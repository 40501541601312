import React from 'react'
import styled from 'styled-components'
import { TableRow } from 'components/common/table'
import { Caption, H3, standardIcons } from '@chordco/component-library'
import { AvailableAudience } from 'types'
import { ComingSoonChip } from './ComingSoonChip'
import { AudienceChip } from './AudienceChip'
import useMixpanel from 'hooks/useMixpanel'
import { MixpanelEventType } from 'utils/mixpanel/types'

type AudienceCardProps = {
  audience: AvailableAudience
  onSelectAudience: (audience: AvailableAudience) => void
}

const {
  BrandChampions,
  ProductLoyalists,
  NewCustomers30,
  NewCustomers90,
  CouponEntrants,
  CustomersToBe,
  AlmostPurchasers,
  CustomersAtRisk,
  MotivatedCustomers,
  GenericAudience,
} = standardIcons

export const AudienceCard: React.FC<AudienceCardProps> = ({ audience, onSelectAudience }) => {
  const sortedCategories = [...audience.categories].sort((a, b) => a.name.localeCompare(b.name))
  const isPredicted = sortedCategories.some(category => category.slug === 'predicted')

  const { trackEvent } = useMixpanel()

  function getAudienceIcon() {
    switch (audience.slug) {
      case 'brand-champions':
        return <BrandChampions />
      case 'product-loyalists':
        return <ProductLoyalists />
      case 'new-customers-30-days':
        return <NewCustomers30 />
      case 'new-customers-90-days':
        return <NewCustomers90 />
      case 'coupon-entrants':
        return <CouponEntrants />
      case 'customers-to-be':
        return <CustomersToBe />
      case 'almost-purchasers':
        return <AlmostPurchasers />
      case 'at-risk-customers':
        return <CustomersAtRisk />
      case 'motivated-customers':
        return <MotivatedCustomers />
      default:
        return <GenericAudience />
    }
  }

  if (audience.enabled) {
    return (
      <Card
        onClick={() => {
          onSelectAudience(audience)
          trackEvent(MixpanelEventType.AudienceTileSelected, {
            'Audience Name': audience.name,
            'Audience Category': audience.categories.map(category => category.slug).join(', '),
            'Audience Set Up': 'Prebuilt',
            'Page Name': 'Audiences',
          })
        }}
      >
        <CardContent>
          <IconContainer predicted={isPredicted}>{getAudienceIcon()}</IconContainer>
          <TextAndBadges>
            <ChipContainer>
              {sortedCategories.length > 0 &&
                sortedCategories.map(category => (
                  <AudienceChip key={category.slug} category={category} />
                ))}
            </ChipContainer>
            <Header>
              <TextContainer>
                <Title>{audience.name}</Title>
                <Description>{audience.description}</Description>
              </TextContainer>
            </Header>
          </TextAndBadges>
        </CardContent>
      </Card>
    )
  } else {
    return (
      <DisabledCard>
        <CardContent>
          <IconContainer predicted={isPredicted}>
            {getAudienceIcon()}
          </IconContainer>
          <TextAndBadges>
            <ChipContainer>
              {sortedCategories.length > 0 &&
                sortedCategories.map(category => (
                  <AudienceChip key={category.slug} category={category} />
                ))}
              <ComingSoonChip />
            </ChipContainer>
            <Header>
              <DisableTextContainer>
                <Title>{audience.name}</Title>
                <Description>{audience.description}</Description>
              </DisableTextContainer>
            </Header>
          </TextAndBadges>
        </CardContent>
      </DisabledCard>
    )
  }
}

const Card = styled(TableRow)`
  height: 150px;
  border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
  border-radius: ${p => p.theme.CardMediumRadius};
  padding: 24px;

  display: flex;
  align-content: center;

  /* When there's only one card, make it take 50% of the container */
  &:only-child {
    width: 50%;
  }

  /* At smaller screen widths, let the single card take the full width */
  @media (max-width: 1200px) {
    &:only-child {
      width: 100%;
    }
  }

  img {
    margin-right: 16px;
  }

  :hover {
    border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
    background-color: ${p => p.theme.ComponentCardBgElevation1};
  }
`

const DisabledCard = styled(Card)`
  cursor: not-allowed;
`

const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: hidden;
  gap: 16px;
`

const TextAndBadges = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
`

const Title = styled(H3)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  display: inline-block;
`

const Description = styled(Caption)`
  color: ${p => p.theme.ContentTertiary};
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`

const DisableTextContainer = styled(TextContainer)`
  color: ${p => p.theme.ContentTertiary};
`

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

const IconContainer = styled.div<{ predicted: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  min-width: 60px;
  border-radius: 50%;
  background-color: ${p =>
    p.predicted
      ? p.theme.BgAccentAdaptiveAAPrimaryContentPrimary
      : p.theme.BgAccentAdaptiveAAPrimaryContentNeutral};
`
