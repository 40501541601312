import { ProvisionAudiencesRequest } from 'types'
import { useIsLoading } from 'redux/utils'
import { useLookerData } from 'redux/state/looker'

export const useProvisionAudiencesForm = (onClose?: () => void) => {
  const { provisionAudiences } = useLookerData()

  const isLoading = useIsLoading('provisionAudiences')

  const submit = async (data: ProvisionAudiencesRequest) => {
    if (!provisionAudiences) return

    await provisionAudiences(data)

    if (onClose) onClose()
  }

  return { submit, isLoading }
}
