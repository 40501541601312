const oldTheme = {
  GREY_10: '#F9FBFC',
  GREY_20: '#F4F8FB',
  GREY_30: '#ECF1F4',
  GREY_40: '#D3DEE3',
  GREY_50: '#B6C5CD',
  GREY_60: '#6C808A',
  GREY_70: '#4E5D65',
  GREY_80: '#2E373C',
  GREY_90: '#151A1D',
  GREY_100: '#000000',
  WHITE: '#FFFFFF',

  TRANSPARENT_04: '#0000000A',
  TRANSPARENT_08: '#00000014',
  TRANSPARENT_16: '#00000028',
  TRANSPARENT_24: '#0000003D',
  TRANSPARENT_32: '#00000051',
  TRANSPARENT_64: '#000000A3',
  TRANSPARENT_88: '#000000E1',
}

const neutrals = {
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  OFF_BLACK: '#232323',
  GREY_5: '#373737',
  GREY_4: '#76777A',
  GREY_3: '#97989A',
  GREY_2: '#D9D8D6',
  GREY_1: '#EEEEEE',
  OFF_TEXT_BASE: '#F9F7EE',
  OFF_TEXT_20: '#FCFBF6',
}

const pink = {
  PRIMARY_10: '#FFEAFF',
  PRIMARY_20: '#FFBDFF',
  PRIMARY_30: '#FF8FFF',
  PRIMARY_40: '#FF61FF',
  PRIMARY_50: '#FF33FF',
  PRIMARY_60: '#DD20DD',
  PRIMARY_70: '#BB11BB',
  PRIMARY_80: '#990699',
  PRIMARY_90: '#770077',
  PRIMARY_100: '#550055',
}

const limeGreen = {
  LIME_GREEN_10: '#FAFFED',
  LIME_GREEN_20: '#EFFFC5',
  LIME_GREEN_30: '#E3FF9E',
  LIME_GREEN_40: '#D8FF76',
  LIME_GREEN_50_BASE: '#C5F54B',
  LIME_GREEN_60: '#A7D337',
  LIME_GREEN_70: '#8AB125',
  LIME_GREEN_80: '#6D8F18',
  LIME_GREEN_90: '#526D0D',
  LIME_GREEN_100: '#374B05',
}

const brightBlue = {
  BRIGHT_BLUE_10: '#EBFCFF',
  BRIGHT_BLUE_20: '#BEF6FF',
  BRIGHT_BLUE_30: '#91F0FF',
  BRIGHT_BLUE_40: '#64EAFF',
  BRIGHT_BLUE_50_BASE: '#36E0FB',
  BRIGHT_BLUE_60: '#23C0D9',
  BRIGHT_BLUE_70: '#14A1B7',
  BRIGHT_BLUE_80: '#088295',
  BRIGHT_BLUE_90: '#006373',
  BRIGHT_BLUE_100: '#004651',
}

const blue = {
  BLUE_10: '#E8E7FF',
  BLUE_20: '#BFBCFF',
  BLUE_30: '#9691FF',
  BLUE_40: '#6D67FF',
  BLUE_50: '#443CFF',
  BLUE_60_BASE: '#1B11FF',
  BLUE_70: '#0900D6',
  BLUE_80: '#0700AD',
  BLUE_90: '#060085',
  BLUE_100: '#04005C',
  BLUE_LILAC: '#ADC3FF',
}

const green = {
  GREEN_10: '#EAFEE8',
  GREEN_20: '#C6FEC0',
  GREEN_30: '#A2FE98',
  GREEN_40: '#7EFE71',
  GREEN_50: '#5AFE49',
  GREEN_60: '#36FE21',
  GREEN_70_BASE: '#21E80C',
  GREEN_80: '#12BF00',
  GREEN_90: '#0E9600',
  GREEN_100: '#0B6E00',
}

const orange = {
  ORANGE_10: '#FFFAF0',
  ORANGE_20: '#FFE9C1',
  ORANGE_30: '#FFD791',
  ORANGE_40: '#FFC662',
  ORANGE_50: '#FFB532',
  ORANGE_60: '#DD9F20',
  ORANGE_70: '#BB8A10',
  ORANGE_80: '#996400',
  ORANGE_90: '#774F00',
  ORANGE_100: '#553900',
}

const red = {
  RED_10: '#FFE8E5',
  RED_20: '#FFC0B7',
  RED_30: '#FF978A',
  RED_40: '#FF6F5C',
  RED_50: '#FE462D',
  RED_60_BASE: '#FF1E00',
  RED_70: '#D61900',
  RED_80: '#AD1400',
  RED_90: '#840F00',
  RED_100: '#5B0A00',
}

const amber = {
  AMBER_10: '#FEF8EC',
  AMBER_20: '#FEEAC2',
  AMBER_30: '#FEDC98',
  AMBER_40: '#FECD6E',
  AMBER_50_BASE: '#FEBF44',
  AMBER_60: '#DDA231',
  AMBER_70: '#BB8620',
  AMBER_80: '#996B13',
  AMBER_90: '#775109',
  AMBER_100: '#553802',
}

const misc = {
  FOCUS: '0 0 0 4px #FF8FFF', // PRIMARY_30
  TRANSPARENT_05: '#0000000C',
}

export const v1Theme = {
  ...oldTheme,
  ...neutrals,
  ...pink,
  ...limeGreen,
  ...brightBlue,
  ...blue,
  ...green,
  ...red,
  ...amber,
  ...misc,
  ...orange,
}

export type Theme = typeof v1Theme
