import { useAuthData } from 'redux/state/auth'

const useCurrentUser = () => {
  const {
    state: { user },
  } = useAuthData()

  return user
}

export default useCurrentUser
