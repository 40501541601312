import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { useIsMobile } from 'hooks'
import { Button, H1, H2, standardIcons } from '@chordco/component-library'
import { Loading } from 'components/common'
import { DashboardEmbed } from './DashboardEmbed'
import { ExploreEmbed } from './ExploreEmbed'
import { CopyDashboardModal } from './CopyDashboardModal'
import { AvailableUserReport, LookerReportType } from 'types'
import { LookEmbed } from './LookEmbed'
import { CopyLookModal } from './CopyLookModal'
import useCanPinReport from 'redux/hooks/useCanPinReport'
import { UnpinModal } from 'components/analytics/reports/UnpinModal'
import { usePinReport } from './usePinReport'
import usePinnedReport from 'redux/hooks/usePinnedReport'
import { useQuickstartData } from 'components/looker/useQuickstartData'

export const Quickstart: React.FC = () => {
  const { slug } = useParams<{ slug: string }>()

  const { quickstart, embedQuickstartUrl, isLoading, isInitialized } = useQuickstartData(slug)

  const isMobile = useIsMobile()
  const Heading = isMobile ? H2 : H1

  const history = useHistory()

  const [showCopyDashboardModal, setShowCopyDashboardModal] = useState(false)
  const [showCopyLookModal, setShowCopyLookModal] = useState(false)

  const handleDashboardCopied = (dashboard?: AvailableUserReport) => {
    setShowCopyDashboardModal(false)
    if (dashboard) {
      history.push(`/analytics/my-reports/${dashboard.slug}`)
    }
  }

  const handleLookCopied = (look?: AvailableUserReport) => {
    setShowCopyLookModal(false)
    if (look) {
      history.push(`/analytics/my-reports/${look.slug}`)
    }
  }

  const handleUseAsTemplate = () => {
    if (quickstart?.metricType === 'dashboard' || quickstart?.metricType === 'lookml_dashboard') {
      setShowCopyDashboardModal(true)
    } else if (quickstart?.metricType === 'look') {
      setShowCopyLookModal(true)
    }
  }

  const canPinUnpin = useCanPinReport()

  const { report, isPinned } = usePinnedReport(slug)

  const [showUnpinModal, setShowUnpinModal] = useState(false)

  const { pin, isLoading: isPinning } = usePinReport()

  const handlePinReport = async () => {
    if (!quickstart) {
      // eslint-disable-next-line no-console
      console.error('Cannot pin a report without quickstart data available')
      return
    }

    await pin({
      reportId: quickstart.id,
      reportType: quickstart.metricType as LookerReportType,
    })
  }

  const closeUnpinModal = () => setShowUnpinModal(false)

  const renderEmbedComponent = (
    quickstart: AvailableUserReport | undefined,
    embedQuickstartUrl: string | null
  ) => {
    if (!embedQuickstartUrl || !quickstart) {
      return null
    }

    switch (quickstart.metricType) {
      case 'dashboard':
      case 'lookml_dashboard':
        return <DashboardEmbed url={embedQuickstartUrl} />
      case 'explore':
        return <ExploreEmbed url={embedQuickstartUrl} />
      case 'look':
        return <LookEmbed url={embedQuickstartUrl} />
      default:
        return null
    }
  }

  if (isLoading) {
    return <Loading />
  }

  if (isInitialized && (!quickstart || !embedQuickstartUrl)) {
    return (
      <Wrapper>
        <BackButton
          onClick={() => history.push('/analytics/quickstarts')}
          icon={standardIcons.ArrowLeft}
          variant="ghost"
        >
          Back
        </BackButton>
        <Heading>Quickstart Not Found</Heading>
        <p>The requested quickstart could not be located. Please try another link.</p>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <BackButton
        onClick={() => history.push('/analytics/quickstarts')}
        icon={standardIcons.ArrowLeft}
        variant="ghost"
      >
        Back
      </BackButton>
      <Header>
        <TextContainer>
          <Heading>{quickstart?.label}</Heading>
          <SubHeading>{quickstart?.description}</SubHeading>
        </TextContainer>
        <ButtonsContainer>
          {isPinned ? (
            <Button
              purpose="tertiary"
              icon={standardIcons.Unpin}
              onClick={() => setShowUnpinModal(true)}
              disabled={!canPinUnpin}
              tooltip={!canPinUnpin ? 'Please contact an admin to unpin this report' : ''}
              tooltipDirection="input-bottom-right"
            >
              Unpin
            </Button>
          ) : (
            <Button
              purpose="tertiary"
              icon={standardIcons.Pin}
              onClick={handlePinReport}
              disabled={!canPinUnpin || isPinning}
              isLoading={isPinning}
              tooltip={!canPinUnpin ? 'Please contact an admin to pin this report' : ''}
              tooltipDirection="input-bottom-right"
            >
              Pin
            </Button>
          )}

          <Button purpose="secondary" icon={standardIcons.Plus} onClick={handleUseAsTemplate}>
            Use Quickstart
          </Button>
        </ButtonsContainer>
      </Header>

      <Container>{renderEmbedComponent(quickstart, embedQuickstartUrl)}</Container>

      {showCopyDashboardModal && quickstart && (
        <CopyDashboardModal
          id={quickstart.id}
          title={quickstart.label}
          reportType={quickstart.metricType as LookerReportType}
          description={quickstart.description}
          onClose={() => setShowCopyDashboardModal(false)}
          onCopied={handleDashboardCopied}
        />
      )}

      {showCopyLookModal && quickstart && (
        <CopyLookModal
          id={quickstart.id}
          title={quickstart.label}
          description={quickstart.description}
          onClose={() => setShowCopyLookModal(false)}
          onCopied={handleLookCopied}
        />
      )}

      {showUnpinModal && canPinUnpin && isPinned && report && (
        <UnpinModal report={report} onUnpin={closeUnpinModal} onClose={closeUnpinModal} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100vh;
`

const BackButton = styled(Button)`
  padding-left: 0;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const Container = styled.div`
  padding: 48px 0;
  height: 100%;
  width: 100%;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const SubHeading = styled.div`
  font-size: 16px;
  color: ${p => p.theme.ContentTertiary};
`
