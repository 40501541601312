import React, { useEffect } from 'react'
import { Loading } from 'components/common'
import { useDataSettingsData } from 'redux/state/dataSettings'
import { DataSettingsContainer } from './DataSettingsContainer'
import { useIsLoading } from 'redux/utils'

const DataSettingsLoader: React.FC = () => {
  const {
    state: { dataSettings, timezones, dataSettingsChangelogEntries },
    getDataSettings,
    getDataTimezones,
    getDataSettingsChangeLog,
  } = useDataSettingsData()

  const isLoading = useIsLoading('getDataSettings', 'getDataTimezones')

  useEffect(() => {
    const fetchAsyncData = async () => {
      if (!dataSettings || !timezones || dataSettingsChangelogEntries) {
        getDataSettings && (await getDataSettings())
        getDataTimezones && (await getDataTimezones())
        getDataSettingsChangeLog && (await getDataSettingsChangeLog())
      }
    }

    fetchAsyncData()
  }, [])

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && timezones && dataSettings && dataSettingsChangelogEntries && (
        <DataSettingsContainer
          timezones={timezones}
          dataSettings={dataSettings}
          dataSettingsChangelogEntries={dataSettingsChangelogEntries}
        />
      )}
    </>
  )
}

export default DataSettingsLoader
