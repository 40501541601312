import { createSlice } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { useStateSliceAndDispatch } from '../utils'
import {
  ImportDashboardResponse,
  ImportLookResponse,
  LookerReportType,
  LookerState,
  MetricsLookupIds,
  ProvisionAudiencesRequest,
} from 'types'
import { useTenantsData } from 'redux/state/tenants'
import { reduxApiClient } from 'redux/api'
import { DateDropdownPeriod } from '@chordco/component-library'

const SLICE_NAME = 'looker'

const initialState: LookerState = {
  looks: {},
  availableReports: {
    dashboards: [],
    looks: [],
    explores: [],
  },
  availableUserReports: {
    userDashboards: [],
    userLooks: [],
  },
  availableAudiences: {
    audiences: [],
  },
  availableUserAudiences: {
    userAudiences: [],
  },
  availableQuickstarts: {
    dashboards: [],
    looks: [],
  },
  availableSharedReports: {
    dashboards: [],
    looks: [],
  },
  tiles: [],
  metrics: {},
  embedUrls: {},
  embedUserUrls: {},
  embedAudienceUrls: {},
  embedUserAudienceUrls: {},
  embedQuickstartUrls: {},
  embedSharedReportUrls: {},
  embedUser: undefined,
  embedUserBootstrapURL: undefined,
}

const {
  getAvailableReports,
  getAvailableUserReports,
  getAvailableAudiences,
  getAvailableUserAudiences,
  getAvailableQuickstarts,
  getEmbedUrl,
  getEmbedUserUrl,
  getEmbedAudienceUrl,
  getEmbedUserAudienceUrl,
  getEmbedQuickstartUrl,
  runLook,
  getTiles,
  getMetrics,
  createDashboard,
  getEmbedUser,
  createEmbedUser,
  importReport,
  copyDashboard,
  copyLook,
  publishDashboard,
  pinDashboard,
  unpinDashboard,
  publishLook,
  pinLook,
  unpinLook,
  copyAudience,
  activateAudience,
  provisionAudiences,
  getSharedDashboards,
  getSharedLooks,
  getEmbedUserBootstrapURL,
} = reduxApiClient

// Reusable function for handling Embed URL API fulfilled cases
// prettier-ignore
const handleEmbedUrlFulfilledCase = (
  state: LookerState,
  action: any,
  stateKey: 'embedUrls' | 'embedUserUrls' | 'embedAudienceUrls',
  sentryMessage: string
) => {
  const { slug } = action.meta.arg;
  const data = action.payload?.data;

  if (!data || !data.url) {
    Sentry.withScope(scope => {
      scope.setTag('embed_url_api_error', true);
      scope.setLevel(Sentry.Severity.Error);
      scope.setExtra('actionPayload', action.payload);
      scope.setExtra('metaArg', action.meta.arg);
      scope.setExtra('slug', slug);
      scope.setExtra('response', action.payload);
      Sentry.captureMessage(sentryMessage);
    });

    // fallback to empty string
    (state[stateKey] as Record<string, string>)[slug] = '';
  } else {
    const { url } = data;
    (state[stateKey] as Record<string, string>)[slug] = url;
  }
}

export const lookerSlice = createSlice({
  name: 'looker',
  initialState,
  reducers: { resetLooker: () => initialState },
  extraReducers: builder => {
    builder.addCase(getAvailableReports.fulfilled, (state, action) => {
      state.availableReports = action.payload.data
    })

    builder.addCase(getAvailableUserReports.fulfilled, (state, action) => {
      state.availableUserReports = action.payload.data
    })

    builder.addCase(getSharedDashboards.fulfilled, (state, action) => {
      state.availableSharedReports.dashboards = action.payload.data.dashboards || []
    })

    builder.addCase(getSharedLooks.fulfilled, (state, action) => {
      state.availableSharedReports.looks = action.payload.data.looks || []
    })

    builder.addCase(getAvailableAudiences.fulfilled, (state, action) => {
      state.availableAudiences = action.payload.data
    })

    builder.addCase(getAvailableUserAudiences.fulfilled, (state, action) => {
      state.availableUserAudiences = action.payload.data
    })

    builder.addCase(getAvailableQuickstarts.fulfilled, (state, action) => {
      state.availableQuickstarts = action.payload.data
    })

    builder.addCase(getEmbedUrl.fulfilled, (state, action) => {
      handleEmbedUrlFulfilledCase(
        state,
        action,
        'embedUrls',
        'getEmbedUrl has returned an unexpected API response structure: Missing URL'
      )
    })

    builder.addCase(getEmbedUserUrl.fulfilled, (state, action) => {
      handleEmbedUrlFulfilledCase(
        state,
        action,
        'embedUserUrls',
        'getEmbedUserUrl has returned an unexpected API response structure: Missing URL'
      )
    })

    builder.addCase(getEmbedAudienceUrl.fulfilled, (state, action) => {
      handleEmbedUrlFulfilledCase(
        state,
        action,
        'embedAudienceUrls',
        'getEmbedAudienceUrl has returned an unexpected API response structure: Missing URL'
      )
    })

    builder.addCase(getEmbedUserAudienceUrl.fulfilled, (state, action) => {
      const { slug } = action.meta.arg
      const { url } = action.payload.data

      state.embedUserAudienceUrls = {
        ...state.embedUserAudienceUrls,
        [slug]: url,
      }
    })

    builder.addCase(getEmbedQuickstartUrl.fulfilled, (state, action) => {
      const { slug } = action.meta.arg
      const { url } = action.payload.data

      state.embedQuickstartUrls = {
        ...state.embedQuickstartUrls,
        [slug]: url,
      }
    })

    builder.addCase(getTiles.fulfilled, (state, action) => {
      const tiles = Array.isArray(action.payload.data) ? action.payload.data : []
      state.tiles = tiles.sort((a, b) => a.id - b.id)
    })

    builder.addCase(runLook.fulfilled, (state, action) => {
      const lookId = action.meta.arg.lookId
      const lookData = action.payload.data

      // todo prevents setting state.looks to have an error and blow up tiles.
      // todo the error can be an object with a 'message' field
      if (Array.isArray(lookData) ? lookData.some(d => !!d?.lookerError) : lookData?.message) {
        return
      }

      state.looks = { ...state.looks, [lookId]: lookData }
    })

    builder.addCase(getMetrics.fulfilled, (state, action) => {
      action.payload.data.forEach(metric => {
        state.metrics[metric.id] = metric
      })
    })

    builder.addCase(createDashboard.fulfilled, (state, action) => {
      const dashboard = action.payload.data.dashboard
      if (dashboard && !state.availableUserReports.userDashboards.some(d => d.slug === dashboard.slug)) {
        state.availableUserReports.userDashboards.push(dashboard)
      }
    })

    builder.addCase(getEmbedUser.fulfilled, (state, action) => {
      state.embedUser = action.payload.data
    })

    builder.addCase(createEmbedUser.fulfilled, (state, action) => {
      state.embedUser = action.payload.data
    })

    builder.addCase(copyDashboard.fulfilled, (state, action) => {
      const dashboard = action.payload.data.dashboard
      if (dashboard && !state.availableUserReports.userDashboards.some(d => d.slug === dashboard.slug)) {
        state.availableUserReports.userDashboards.push(dashboard)
      }
    })

    builder.addCase(copyLook.fulfilled, (state, action) => {
      const look = action.payload.data.look
      if (look && !state.availableUserReports.userLooks.some(l => l.slug === look.slug)) {
        state.availableUserReports.userLooks.push(look)
      }
    })

    builder.addCase(copyAudience.fulfilled, (state, action) => {
      const audience = action.payload.data.audience
      if (audience && !state.availableUserAudiences.userAudiences.some(a => a.slug === audience.slug)) {
        state.availableUserAudiences.userAudiences.push(audience)
      }
    })

    builder.addCase(pinLook.fulfilled, (state, action) => {
      const look = action.payload.data.look
      if (look && !state.availableReports.looks.some(l => l.slug === look.slug)) {
        state.availableReports.looks.push(look)
      }
    })

    builder.addCase(pinDashboard.fulfilled, (state, action) => {
      const dashboard = action.payload.data.dashboard
      if (dashboard && !state.availableReports.dashboards.some(d => d.slug === dashboard.slug)) {
        state.availableReports.dashboards.push(dashboard)
      }
    })

    builder.addCase(publishLook.fulfilled, (state, action) => {
      const look = action.payload.data.look
      if (look && !state.availableSharedReports.looks.some(l => l.slug === look.slug)) {
        state.availableSharedReports.looks.push(look)
      }
    })

    builder.addCase(publishDashboard.fulfilled, (state, action) => {
      const dashboard = action.payload.data.dashboard
      if (dashboard && !state.availableSharedReports.dashboards.some(d => d.slug === dashboard.slug)) {
        state.availableSharedReports.dashboards.push(dashboard)
      }
    })

    builder.addCase(importReport.fulfilled, (state, action) => {
      const { metricType } = action.meta.arg
      if (metricType === 'look') {
        const look = (action.payload.data as ImportLookResponse).look
        if (look && !state.availableUserReports.userLooks.some(l => l.slug === look.slug)) {
          state.availableUserReports.userLooks.push(look)
        }
      } else if (metricType === 'dashboard') {
        const dashboard = (action.payload.data as ImportDashboardResponse).dashboard
        if (dashboard && !state.availableUserReports.userDashboards.some(d => d.slug === dashboard.slug)) {
          state.availableUserReports.userDashboards.push(dashboard)
        }
      }
    })

    builder.addCase(activateAudience.fulfilled, (state, action) => {
      const audience = action.payload.data.audience
      if (audience && !state.availableUserAudiences.userAudiences.some(a => a.slug === audience.slug)) {
        state.availableUserAudiences.userAudiences.push(audience)
      }
    })

    builder.addCase(unpinLook.fulfilled, (state, action) => {
      const look = action.payload.data.look
      if (look) {
        state.availableReports.looks = state.availableReports.looks.filter(l => l.slug !== look.slug)
      }
    })

    builder.addCase(unpinDashboard.fulfilled, (state, action) => {
      const dashboard = action.payload.data.dashboard
      if (dashboard) {
        state.availableReports.dashboards = state.availableReports.dashboards.filter(d => d.slug !== dashboard.slug)
      }
    })

    builder.addCase(getEmbedUserBootstrapURL.fulfilled, (state, action) => {
      state.embedUserBootstrapURL = action.payload.data.url
    })
  },
})

export default lookerSlice.reducer

export const useLookerData = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)

  const {
    state: { currentTenant, currentStore },
  } = useTenantsData()

  const tenantId = currentTenant?.id
  const storeId = currentStore?.id

  if (!tenantId || !storeId) {
    return { state }
  }

  return {
    state,
    getAvailableReports: () => dispatch(getAvailableReports({ tenantId, storeId })),
    getAvailableUserReports: () => dispatch(getAvailableUserReports({ tenantId, storeId })),
    getAvailableUserAudiences: () => dispatch(getAvailableUserAudiences({ tenantId })),
    getAvailableQuickstarts: (forceRefresh = false) =>
      dispatch(getAvailableQuickstarts({ tenantId, storeId, forceRefresh })),
    getEmbedUrl: (slug: string, type: LookerReportType, theme?: string) =>
      dispatch(getEmbedUrl({ tenantId, storeId, slug, type, theme })),
    getEmbedUserUrl: (slug: string, type: LookerReportType, theme?: string) =>
      dispatch(getEmbedUserUrl({ tenantId, storeId, slug, type, theme })),
    getEmbedQuickstartUrl: (slug: string, type: LookerReportType, theme?: string) =>
      dispatch(getEmbedQuickstartUrl({ tenantId, storeId, slug, type, theme })),
    runLook: (lookId: number) => dispatch(runLook({ tenantId, storeId, lookId })),
    getTiles: (timePeriod: DateDropdownPeriod) => dispatch(getTiles({ tenantId, storeId, timePeriod })),
    getMetrics: (metricIds: (MetricsLookupIds | number)[]) => dispatch(getMetrics({ tenantId, storeId, metricIds })),
    createDashboard: (title: string, description: string, folderId: string) =>
      dispatch(createDashboard({ tenantId, storeId, title, description, folderId })),
    getEmbedUser: () => dispatch(getEmbedUser()),
    createEmbedUser: () => dispatch(createEmbedUser()),
    importReport: (slug: string, folderId: string, metricType: LookerReportType) =>
      dispatch(importReport({ tenantId, storeId, slug, folderId, metricType })),

    copyDashboard: (
      dashboardId: string,
      dashboardTitle: string,
      dashboardDescription: string,
      folderId: string,
      reportType: LookerReportType
    ) =>
      dispatch(
        copyDashboard({
          tenantId,
          storeId,
          dashboardId,
          dashboardTitle,
          dashboardDescription,
          folderId,
          reportType,
        })
      ),
    copyLook: (lookId: string, lookTitle: string, lookDescription: string, folderId: string) =>
      dispatch(
        copyLook({
          tenantId,
          storeId,
          lookId,
          lookTitle,
          lookDescription,
          folderId,
        })
      ),
    publishDashboard: (
      dashboardName: string,
      dashboardDescription: string,
      dashboardSlug: string,
      replaceExisting: boolean
    ) =>
      dispatch(
        publishDashboard({
          tenantId,
          storeId,
          dashboardName,
          dashboardDescription,
          dashboardSlug,
          replaceExisting,
        })
      ),
    pinDashboard: (dashboardId: string) =>
      dispatch(
        pinDashboard({
          tenantId,
          storeId,
          dashboardId,
        })
      ),
    unpinDashboard: (dashboardSlug: string) =>
      dispatch(
        unpinDashboard({
          tenantId,
          storeId,
          dashboardSlug,
        })
      ),
    publishLook: (lookName: string, lookDescription: string, lookSlug: string, replaceExisting: boolean) =>
      dispatch(
        publishLook({
          tenantId,
          storeId,
          lookName,
          lookDescription,
          lookSlug,
          replaceExisting,
        })
      ),
    pinLook: (lookId: string) =>
      dispatch(
        pinLook({
          tenantId,
          storeId,
          lookId,
        })
      ),
    unpinLook: (lookSlug: string) =>
      dispatch(
        unpinLook({
          tenantId,
          storeId,
          lookSlug,
        })
      ),
    getSharedDashboards: () => dispatch(getSharedDashboards({ tenantId, storeId })),
    getSharedLooks: () => dispatch(getSharedLooks({ tenantId, storeId })),
    getAvailableAudiences: () => dispatch(getAvailableAudiences({ tenantId, storeId })),
    getEmbedAudienceUrl: (slug: string, type: LookerReportType, theme?: string) =>
      dispatch(getEmbedAudienceUrl({ tenantId, slug, type, theme })),
    getEmbedUserAudienceUrl: (slug: string, theme?: string) =>
      dispatch(getEmbedUserAudienceUrl({ tenantId, slug, theme })),
    getEmbedUserBootstrapURL: () => dispatch(getEmbedUserBootstrapURL({ tenantId, storeId })),
    copyAudience: (audienceId: string, audienceTitle: string, audienceDescription: string, folderId: string) =>
      dispatch(
        copyAudience({
          tenantId,
          audienceId,
          audienceTitle,
          audienceDescription,
          folderId,
        })
      ),
    activateAudience: (audienceName: string, audienceDescription: string, audienceId: string) =>
      dispatch(
        activateAudience({
          tenantId,
          audienceName,
          audienceDescription,
          audienceId,
        })
      ),
    provisionAudiences: (data: ProvisionAudiencesRequest) =>
      dispatch(
        provisionAudiences({
          tenantId,
          audienceIds: data.audiences.map(a => a.id),
        })
      ).then(() => dispatch(getAvailableAudiences({ tenantId, storeId }))),
  }
}
