import { AvailableAudience } from 'types'
import { valTypes } from 'validationSchemas'

const provisionAudiencesSchema = (tenantAudiences: AvailableAudience[]) => ({
  audiences: {
    label: 'Audiences',
    type: valTypes.array,
    initialValue: tenantAudiences,
    required: true,
  },
})

export default provisionAudiencesSchema
