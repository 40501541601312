import React from 'react'
import { Avatar, Chip, H3, useTheme } from '@chordco/component-library'
import styled from 'styled-components'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import moment from 'moment'
import { useUserRoleStateDisplay } from 'hooks/useUserRoleHelper'

export default function ChangeLogTable({ dataSettingsChangelogEntries }) {
  const theme = useTheme()

  function userRolePill(role) {
    const { text, color, variant } = useUserRoleStateDisplay(role)

    return <Chip text={text} color={color} variant={variant} />
  }

  return (
    <ChangeLogContainer>
      <TitleContainer>
        <H3>Setting Change Log</H3>
      </TitleContainer>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, borderTop: 0, borderRight: 0, borderLeft: 0, borderBottom: 0 }}
          size="medium"
          aria-label="changelog table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'left' }}>Changed</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'left' }}>Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'left' }}>Time</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'left' }}>By User</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'left' }}>User Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSettingsChangelogEntries.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left">
                  {row.updatedSettings.join(', ').replace(/_/g, ' ')}
                </TableCell>
                <TableCell align="left" sx={{ color: 'grey' }}>
                  {moment(row.date).format('MMMM, D YYYY')}
                </TableCell>
                <TableCell align="left" sx={{ color: 'grey' }}>
                  {row.time.toUpperCase()}
                </TableCell>
                <TableCell align="left">
                  <StyledAvatar
                    userName={row.username}
                    size={24}
                    randomizeBgColor={false}
                    backgroundColor={theme.BLUE_LILAC}
                  />{' '}
                  {row.username}
                </TableCell>
                <TableCell align="left">{userRolePill(row.userRole)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ChangeLogContainer>
  )
}

const ChangeLogContainer = styled.div`
  top: 10px;
  position: float;
  margin-left: 10px;
  padding-bottom: 40px;
  padding-top 20px;
`

const TitleContainer = styled.div`
  position: relative;
  top: 10px;
  padding-bottom: 20px;
`

const StyledAvatar = styled(Avatar)`
  color: ${p => p.theme.BLACK};
  size: 24px;
`
