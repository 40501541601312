import React from 'react'
import {
  MRT_TableOptions,
  MRT_RowData,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_Row,
} from 'material-react-table'
import { Box, IconButton, Tooltip } from '@mui/material'
import { Button, standardIcons } from '@chordco/component-library'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Refresh } from '@mui/icons-material'
import { hubClient } from 'api/http'
import { useExperiments } from 'hooks'

const { Plus, Check, Download, Upload, Trash } = standardIcons

export function getToolbarActions<TData extends MRT_RowData>(
  runIntegrityCheck: () => void,
  importCSVSheet: () => void,
  clearSheetData: () => void,
  downloadSheet: () => void,
  processingSheetUpload: boolean,
  slug: string,
  enableEditing: boolean,
  hasData: boolean
): Partial<MRT_TableOptions<TData>> {
  const treatment = useExperiments('hub_upload_sheet')
  const enableSheetActions = enableEditing && treatment?.variation === 'on'

  const downloadSheetTemplate = async () => {
    const path = `/config_sheets/${slug}/download_template`
    const baseURL = hubClient.defaults.baseURL?.endsWith('/')
      ? hubClient.defaults.baseURL.slice(0, -1)
      : hubClient.defaults.baseURL
    const url = `${baseURL}${path}`
    const link = document.createElement('a')
    link.href = url
    link.target = '_blank'
    link.download = `${slug}.xlsx`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return {
    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{ display: 'flex', gap: '0.75rem', marginBottom: '1rem' }}>
        {table.options.enableEditing && (
          <>
            <Button
              disabled={processingSheetUpload}
              icon={Plus}
              onClick={() => table.setCreatingRow(true)}
              name="New row"
              location="Model Amplifications"
            >
              New row
            </Button>
            {enableSheetActions && (
              <>
                {hasData ? (
                  <Button
                    icon={Download}
                    purpose="tertiary"
                    onClick={downloadSheet}
                    name="Download CSV"
                    location="Model Amplifications"
                  >
                    Download CSV
                  </Button>
                ) : (
                  <Button
                    purpose="tertiary"
                    onClick={downloadSheetTemplate}
                    icon={Download}
                    name="Download template"
                    location="Model Amplifications"
                  >
                    Download template
                  </Button>
                )}

                <Button
                  icon={Upload}
                  disabled={processingSheetUpload}
                  purpose="tertiary"
                  onClick={importCSVSheet}
                  name="Upload CSV"
                  location="Model Amplifications"
                >
                  Upload CSV
                </Button>
                {hasData && (
                  <Button
                    icon={Trash}
                    disabled={processingSheetUpload}
                    purpose="tertiary"
                    onClick={clearSheetData}
                    name="Clear Data"
                    location="Model Amplifications"
                  >
                    Clear Data
                  </Button>
                )}
              </>
            )}

            <Button
              icon={Check}
              variant="outlined"
              disabled={processingSheetUpload}
              purpose="tertiary"
              onClick={runIntegrityCheck}
              name="Run Integrity Check"
              location="Model Amplifications"
            >
              Run Integrity Check
            </Button>
          </>
        )}
      </Box>
    ),
  }
}

export function getToolbarInternalActions<TData extends MRT_RowData>(
  reloadTableData: () => void
): Partial<MRT_TableOptions<TData>> {
  return {
    renderToolbarInternalActions: ({ table }) => (
      <>
        <Tooltip title="Clear cache and refresh">
          <IconButton onClick={reloadTableData}>
            <Refresh />
          </IconButton>
        </Tooltip>

        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleDensePaddingButton table={table} />
        <MRT_ToggleFullScreenButton table={table} />
      </>
    ),
  }
}

export function getRowActions<TData extends MRT_RowData>(
  openDeleteConfirmModal: ((row: MRT_Row<TData>) => void) | undefined
): Partial<MRT_TableOptions<TData>> {
  function handleDeleteRow(event, row: MRT_Row<TData>) {
    event.stopPropagation() // prevent row to enter edit mode
    openDeleteConfirmModal?.(row)
  }

  return {
    renderRowActions: ({ row, table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '0.75rem',
          justifyContent: 'center',
        }}
      >
        <Tooltip title="Edit Row">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon fontSize="small" color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Row">
          <IconButton onClick={event => handleDeleteRow(event, row)}>
            <DeleteIcon fontSize="small" color="primary" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  }
}
