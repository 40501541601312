import React from 'react'
import { AlertModal } from '@chordco/component-library'
import { QuestionMark } from '@mui/icons-material'
import { useDataSettingsData } from 'redux/state/dataSettings'
import { DataSettingsPayload } from 'types'

type Props = {
  onClose: () => void
  data: DataSettingsPayload
}
export const SaveConfirmationModal: React.FC<Props> = ({ onClose, data }) => {
  const { updateDataSettings } = useDataSettingsData()

  const handleSaveChanges = () => {
    updateDataSettings && updateDataSettings(data)
    onClose()
  }

  return (
    <AlertModal
      onClose={onClose}
      icon={QuestionMark}
      content={`Are you sure you want to save these changes?`}
      centered
      buttons={[
        {
          id: 1,
          text: 'Cancel',
          onClick: onClose,
          purpose: 'tertiary',
          disabled: false,
        },
        {
          id: 2,
          text: 'Save',
          onClick: handleSaveChanges,
          purpose: 'primary',
          isLoading: false,
          disabled: false,
        },
      ]}
    />
  )
}
