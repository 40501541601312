export enum MixpanelEventType {
  ButtonClicked = 'Button Clicked',
  SheetOpened = 'Sidesheet Opened',
  SheetClosed = 'Sidesheet Closed',
  DataSourceClicked = 'Data Source Clicked',
  LinkClicked = 'Link Clicked',
  SourceTileSelected = 'Tile Selected',
  ActivationTileSelected = 'Activation Tile Selected',
  AudienceTileSelected = 'Audience Tile Selected',
  AudienceActivated = 'Audience Activated',
  DataDestinationClicked = 'Data Destination Clicked',
  ActionItemClicked = 'Action Item Clicked',
  DestinationConnected = 'Destination Connected',
  TabClicked = 'Tab Clicked',
  ModalOpened = 'Modal Opened',
  ModalClosed = 'Modal Closed',
  FilterClicked = 'Filter Clicked',
  ModelAmplificationSelected = 'Model Amplification Selected',
}

export type MixpanelEventData = {
  [MixpanelEventType.SheetOpened]: {
    'Sidesheet Name': string
    'Page Name': string
  }
  [MixpanelEventType.SheetClosed]: {
    'Sidesheet Name': string
    'Page Name': string
  }
  [MixpanelEventType.ButtonClicked]: {
    'Button Name': string
    'Button Purpose': string
    'Page Name': string
    'Slidesheet Name'?: string
  }
  [MixpanelEventType.DataSourceClicked]: {
    'Page Name': string
    'Table Name': string
    'Data Source Name': string
    'Modeled Data': string
    'Setup Details': string
    'Sync Status': string
    'Last Synced': string
  }
  [MixpanelEventType.LinkClicked]: {
    'Link Name': string
    'Link URL': string
    'Page Name': string
    'Slidesheet Name': string
  }
  [MixpanelEventType.SourceTileSelected]: {
    'Data Source Name': string
    'Modeled Data': string
    'Page Name': string
    'Slidesheet Name': string
  }
  [MixpanelEventType.DataDestinationClicked]: {
    'Page Name': string
    'Table Name': string
    'Destination Name': string
    'Destination Created At': string
    'Destination Status': string
    'Last Tested At': string
  }
  [MixpanelEventType.ActionItemClicked]: {
    'Icon Name': string
    'Page Name': string
    'Slidesheet Name': string
    'Table Name': string
  }
  [MixpanelEventType.ActivationTileSelected]: {
    'Destination Name': string
    'Page Name': string
    'Slidesheet Name': string
  }
  [MixpanelEventType.AudienceTileSelected]: {
    'Audience Name': string
    'Audience Category': string
    'Audience Set Up': string
    'Page Name': string
  }
  [MixpanelEventType.DestinationConnected]: {
    'Destination Name': string
    'Activation Successful?': string
  }
  [MixpanelEventType.TabClicked]: {
    'Tab Name': string
    'Page Name': string
  }
  [MixpanelEventType.ModalOpened]: {
    'Modal Name': string
    'Modal Type': string
    'Page Name': string
  }
  [MixpanelEventType.ModalClosed]: {
    'Modal Name': string
    'Modal Type': string
    'Page Name': string
  }
  [MixpanelEventType.FilterClicked]: {
    'Filter Name': string
    'Page Name': string
  }
  [MixpanelEventType.AudienceActivated]: {
    'Audience Name': string
    'Page Name': string
  }
  [MixpanelEventType.ModelAmplificationSelected]: {
    'Amplification Name': string
    'Amplification Status': string
    'Page Name': string
  }
}
