import React from 'react'
import { H3, Label, Tooltip } from '@chordco/component-library'
import styled from 'styled-components'
import { Box, FormControl, IconButton, MenuItem, Select } from '@mui/material'
import { TimezoneValue } from 'types'
import { InfoOutlined } from '@mui/icons-material'
import useUserRole from 'redux/hooks/useUserRole'

type TimezonePanelProps = {
  timezone: string
  lookerTimezones: Record<string, TimezoneValue>
  setTimezone: (timezone: string) => void
}

export const TimezonePanel: React.FC<TimezonePanelProps> = ({
  timezone,
  lookerTimezones,
  setTimezone,
}) => {
  const handleTimezoneChange = event => {
    setTimezone(event.target.value)
  }

  const role = useUserRole()
  const disableMemberUser = role === 'no_role'

  return (
    <>
      <TimezoneContainer>
        <TitleContainer>
          <H3>Set Time Zone</H3>
          <TooltipContainer>
            <Tooltip
              text={`Change or update the default time zone for your organization's analytics reporting. The Chord default time zone is America - New York.`}
              direction="right"
              customWidth="300px"
            >
              <IconButton size="small">
                <InfoOutlined fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </TooltipContainer>
        </TitleContainer>

        <Label>Company data time zone</Label>
        <FormControl sx={{ minWidth: 350 }} size="small" disabled={disableMemberUser}>
          <Select id="timezone-picker" value={timezone} onChange={handleTimezoneChange}>
            {Object.entries(lookerTimezones).map(([key]) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <BoxContainer>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}></Box>
        </BoxContainer>
      </TimezoneContainer>
    </>
  )
}

const TimezoneContainer = styled.div`
  position: relative;
  top: 0px;
  margin-left: 10px;
  padding-bottom: 25px;
  padding-top: 20px;
`
const TooltipContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 145px;
`

const TitleContainer = styled.div`
  position: relative;
  top: 10px;
  padding-bottom: 20px;
`

const BoxContainer = styled.div`
  padding-top: 40px;
`
