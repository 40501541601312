import { Chip, H5 } from '@chordco/component-library'
import { FormField } from 'hooks'
import React from 'react'
import styled from 'styled-components'
import { AvailableAudience } from 'types'

type Props = {
  fields: Record<string, FormField>
  tenantAudiences: AvailableAudience[]
}

export const ProvisionAudiencesConfirmation: React.FC<Props> = ({
  fields,
  tenantAudiences,
}) => {
  const inputAudiences = fields.audiences.value as AvailableAudience[]

  const removedAudiences = tenantAudiences.filter(
    a => !inputAudiences.some(input => input.slug === a.slug)
  )
  const addedAudiences = inputAudiences.filter(
    input => !tenantAudiences.some(a => a.slug === input.slug)
  )

  const renderChips = (audiences: AvailableAudience[], message: string) => (
    <>
      <H5>{message}</H5>
      <Row>
        <Label>
          <ChipContainer>
            {audiences.length > 0 ? (
              audiences.map(audience => (
                <Chip
                  key={audience.slug}
                  text={audience.name}
                  variant="solid-secondary"
                  color="primary"
                />
              ))
            ) : (
              <Label>None</Label>
            )}
          </ChipContainer>
        </Label>
      </Row>
    </>
  )

  return (
    <Wrapper>
      <Label>Please confirm the following information:</Label>
      {renderChips(addedAudiences, 'New Audiences to be added:')}
      {renderChips(removedAudiences, 'Existing Audiences to be removed:')}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

const Label = styled.div`
  margin-bottom: 4px;
  color: ${p => p.theme.ContentSecondary};
  white-space: pre-wrap;
`

const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`
