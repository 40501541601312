import { useEffect, useMemo, useState } from 'react'
import { useLookerData } from 'redux/state/looker'
import useEmbedQuickstartUrl from 'components/looker/useEmbedQuickstartUrl'
import { useIsLoading } from 'redux/utils'
import { AvailableUserReport } from 'types'

export interface UseQuickstartDataResult {
  quickstart: AvailableUserReport | undefined
  embedQuickstartUrl: string | null
  isLoading: boolean
  isInitialized: boolean
}

/**
 * Fetches report data and embed URL for a given quickstart slug
 */
export const useQuickstartData = (slug: string): UseQuickstartDataResult => {
  const {
    state: { availableQuickstarts },
    getAvailableQuickstarts,
  } = useLookerData()

  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    if (getAvailableQuickstarts) {
      getAvailableQuickstarts().finally(() => setIsInitialized(true))
    }
  }, [])

  const quickstart = useMemo(() => {
    const allQuickstarts = availableQuickstarts.dashboards.concat(availableQuickstarts.looks)
    const foundQuickstart = allQuickstarts.find(quickstart => quickstart.slug === slug)
    return foundQuickstart
  }, [availableQuickstarts, slug])

  const { embedQuickstartUrls, isLoading: isLoadingURL } = useEmbedQuickstartUrl(
    slug,
    quickstart?.metricType || 'dashboard'
  )

  const embedQuickstartUrl = embedQuickstartUrls?.[slug] || null

  const isLoadingQuickstarts = useIsLoading('getAvailableQuickstarts')
  const isLoading = isLoadingQuickstarts || isLoadingURL

  return {
    quickstart,
    embedQuickstartUrl,
    isLoading,
    isInitialized,
  }
}
