import React from 'react'
import styled from 'styled-components'
import humps from 'humps'

type Props = {
  processingUpload: boolean
  sheetUpload: {
    processing?: boolean
    completed?: boolean
    failed?: boolean
    message?: string | null
    validationErrors?: { [key: string]: number[] }
  }
  refreshViaWarning: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

export const SheetUploadFeedback: React.FC<Props> = ({
  processingUpload,
  sheetUpload,
  refreshViaWarning,
}) => {
  const isProcessing = processingUpload || sheetUpload.processing
  const hasFailed = sheetUpload.failed
  const hasSucceeded = sheetUpload.completed
  const rawValidationErrors = sheetUpload.validationErrors || {}
  const validationErrors = humps.decamelizeKeys(rawValidationErrors, { separator: ' ' })

  return (
    <>
      {isProcessing && (
        <WarningWrapper>
          Theres a file processing in background, please{' '}
          <WarningLink href="" onClick={refreshViaWarning}>
            refresh
          </WarningLink>{' '}
          in a minute to verify the result.
        </WarningWrapper>
      )}

      {!isProcessing && hasFailed && (
        <ErrorWrapper>The uploaded csv failed to process. {sheetUpload.message}</ErrorWrapper>
      )}

      {!isProcessing && hasSucceeded && (
        <ModelAmplificationsConfirmationContainer>
          <ModelAmplificationsContent>
            <SuccessWrapper>
              The uploaded csv has been processed. {sheetUpload.message}{' '}
            </SuccessWrapper>

            {Object.keys(validationErrors).length > 0 && (
              <WarningWrapper>
                <div>
                  {Object.keys(validationErrors).length} validation errors found. Please review them
                  below:
                </div>
                <ul>
                  {Object.entries(validationErrors).map(([key, value]) => (
                    <li key={key}>
                      {key}. example affected rows: {(value as number[]).join(', ')}
                    </li>
                  ))}
                </ul>
              </WarningWrapper>
            )}
          </ModelAmplificationsContent>
        </ModelAmplificationsConfirmationContainer>
      )}
    </>
  )
}

const ModelAmplificationsConfirmationContainer = styled.div`
  position: relative;
  display: flex;
  width: 98%;
  border-radius: 8px;
`

const WarningWrapper = styled.div`
  padding: 0 20px 10px 20px;
  width: 98%;
  color: ${p => p.theme.ContentAccentWarning};
`

const WarningLink = styled.a`
  color: ${p => p.theme.ContentPrimary};
  text-decoration: none;
  cursor: pointer;
`

const ErrorWrapper = styled.div`
  padding: 0 20px 10px 20px;
  width: 98%;
  color: ${p => p.theme.ContentAccentError};
`

const SuccessWrapper = styled.div`
  padding: 0 20px 10px 20px;
  color: ${p => p.theme.ContentAccentSuccess};
`

const ModelAmplificationsContent = styled.div`
  padding: 12px;
`
