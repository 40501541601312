import React from 'react'
import { useIsMobile } from 'hooks'
import { H1, H2 } from '@chordco/component-library'
import styled, { css } from 'styled-components'
import HelpButton from 'components/help/HelpButton'

interface Props {
  children?: React.ReactNode
  title: string
  subtitle?: string
  hideBottomBorder?: boolean
  margin?: string
  helpFile?: string
  showHelpOnPageLoad?: boolean
}

export const Header: React.FC<Props> = ({
  children,
  title,
  subtitle,
  margin,
  hideBottomBorder = false,
  helpFile,
  showHelpOnPageLoad = false,
}) => {
  const isMobile = useIsMobile()
  const Heading = isMobile ? H2 : H1

  return (
    <Container margin={margin}>
      <Heading>{title}</Heading>
      {subtitle && <SubHeading>{subtitle}</SubHeading>}
      {helpFile && <HelpButton helpFile={helpFile} helpModalOpened={showHelpOnPageLoad} />}
      {children}
      {!hideBottomBorder && <HeaderHr isMobile={isMobile} />}
    </Container>
  )
}

const Container = styled.div<{ margin?: string }>`
  position: relative;
  margin: ${p => p.margin || 0};
`

const SubHeading = styled.div`
  margin-top: 8px;
  color: ${p => p.theme.ContentSecondary};
`

export const Hr = styled.div<{
  isMobile: boolean
  bottom?: boolean
}>`
  border-top: solid 1px ${p => p.theme.BorderHairline};
  margin: 0 ${p => (p.isMobile ? '-16px' : '-300px')};

  ${p =>
    p.bottom &&
    css`
      margin-top: 64px;
    `}
`

const HeaderHr = styled(Hr)`
  margin-top: ${p => (p.isMobile ? 24 : 32)}px;
`
