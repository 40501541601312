import { AxiosRequestConfig, hubClient } from '../http'
import { DataSettings, Timezones, DataSettingsPayload, DataSettingsChangelogEntries } from 'types'

export interface DataSettingsClient {
  getDataSettings: (tenantId: number) => Promise<DataSettings>
  getDataTimezones: (tenantId: number) => Promise<Timezones>
  getDataSettingsChangeLog: (tenantId: number) => Promise<DataSettingsChangelogEntries>
  updateDataSettings: (data: { tenantId: number; data: DataSettingsPayload }) => Promise<void>
}

export const dataSettingsClient: DataSettingsClient = {
  getDataSettings: tenantId =>
    hubClient.get(`tenants/${tenantId}/data_settings/tenant_data_settings`),
  getDataTimezones: tenantId => {
    const config: AxiosRequestConfig = { skipCamelize: true }
    return hubClient.get(`tenants/${tenantId}/data_settings/looker_timezone_settings`, config)
  },
  getDataSettingsChangeLog: tenantId =>
    hubClient.get(`tenants/${tenantId}/data_settings/tenant_data_settings_change_log`),
  updateDataSettings: ({ tenantId, data }) => {
    return hubClient.put(`tenants/${tenantId}/data_settings/update_tenant_data_settings`, data)
  },
}
