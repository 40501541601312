import React from 'react'
import styled from 'styled-components'
import { getAcronym, generateColorFromText } from '../../utils'

export type AvatarSize = 16 | 24 | 32 | 40 | 48 | 80

export interface AvatarProps {
  size?: AvatarSize
  src?: string
  alt?: string
  userName?: string
  logo?: boolean
  onClick?: any
  randomizeBgColor?: boolean
  backgroundColor?: string
}

export const Avatar: React.FC<AvatarProps> = ({
  src,
  alt,
  size = 32,
  userName = '',
  logo,
  randomizeBgColor = true,
  backgroundColor = 'pink',
  ...props
}) => {
  const initials = getAcronym(userName)

  return src ? (
    <Img {...props} src={src} alt={alt} size={size} logo={logo} />
  ) : (
    <Initials
      {...props}
      size={size}
      backgroundColor={randomizeBgColor ? generateColorFromText(initials || '') : backgroundColor}
    >
      {initials?.substring(0, size == 16 ? 1 : 2)}
    </Initials>
  )
}

const Img = styled.img<{ size?: number; logo?: boolean }>`
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  border-radius: ${p => (p.logo ? '24' : '50')}%;
`

export const Initials = styled.span<{ size: number; backgroundColor: string }>`
  position: relative;
  display: inline-block;
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  line-height: ${p => p.size}px;
  border-radius: 100%;
  background-color: ${p => p.backgroundColor};
  color: ${p => p.theme.BLACK};
  text-align: center;
  //margin-top: -2px; // lines up with img tag
  text-transform: uppercase;
  font-size: ${p => {
    if (p.size >= 40) {
      return '16px'
    } else if (p.size >= 32) {
      return '14px'
    } else {
      return '10px'
    }
  }};
`
