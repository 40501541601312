import { useEffect, useMemo, useState } from 'react'
import { useLookerData } from 'redux/state/looker'
import useEmbedAudienceUrl from 'components/looker/useEmbedAudienceUrl'
import { useIsLoading } from 'redux/utils'
import { AvailableAudience } from 'types'

export interface UseAudienceDataResult {
  audience: AvailableAudience | undefined
  embedAudienceUrl: string | null
  isLoading: boolean
  isInitialized: boolean
}

/**
 * Fetches audience data and embed URL for a given audience slug
 */
export const useAudienceData = (slug: string): UseAudienceDataResult => {
  const {
    state: { availableAudiences },
    getAvailableAudiences,
  } = useLookerData()

  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    if (getAvailableAudiences) {
      getAvailableAudiences().finally(() => setIsInitialized(true))
    }
  }, [])

  const isLoadingAudiences = useIsLoading('getAvailableAudiences')

  const audience = useMemo(
    () => availableAudiences.audiences.find(audience => audience.slug === slug),
    [availableAudiences, slug]
  )

  const { embedAudienceUrls, isLoading: isLoadingURL } = useEmbedAudienceUrl(slug, 'audience')

  const embedAudienceUrl = embedAudienceUrls?.[slug] || null

  const isLoading = isLoadingAudiences || isLoadingURL

  return {
    audience,
    embedAudienceUrl,
    isLoading,
    isInitialized,
  }
}
