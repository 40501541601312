import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'hooks'
import { FormCheckbox } from 'components/common'
import { Button, Modal } from '@chordco/component-library'
import { useProvisionAudiencesForm } from 'redux/hooks/useProvisionAudiencesForm'
import { ProvisionAudiencesConfirmation } from './ProvisionAudiencesConfirmation'
import { AvailableAudience } from 'types'
import provisionAudiencesSchema from './ProvisionAudiencesSchema'
import { useProvisioningData } from 'redux/state/provisioning'
import { useIsLoading } from 'redux/utils'
import { useTenantsData } from 'redux/state/tenants'

type Props = {
  onClose: () => void
  tenantAudiences: AvailableAudience[]
}

enum ProvisionAudiencesFormSections {
  Input = 'input',
  Confirm = 'confirm',
}

export const ProvisionAudiencesForm: React.FC<Props> = ({ onClose, tenantAudiences }) => {
  const {
    state: { currentStore },
  } = useTenantsData()

  const [section, setSection] = useState(ProvisionAudiencesFormSections.Input)

  const sectionTransitions = {
    [ProvisionAudiencesFormSections.Input]: {
      next: ProvisionAudiencesFormSections.Confirm,
      prev: ProvisionAudiencesFormSections.Input,
    },
    [ProvisionAudiencesFormSections.Confirm]: {
      prev: ProvisionAudiencesFormSections.Input,
    },
  }

  const navigateSection = (direction: 'next' | 'prev') => {
    const nextSection = sectionTransitions[section][direction]
    if (nextSection) setSection(nextSection)
  }

  const goToNextSection = () => navigateSection('next')
  const goToPreviousSection = () => navigateSection('prev')

  const {
    state: { audiences },
    getAudiences,
  } = useProvisioningData()

  const isLoadingAudiences = useIsLoading('getAudiences')

  useEffect(() => {
    if (!audiences.length && !isLoadingAudiences) {
      getAudiences()
    }
  }, [audiences.length, getAudiences])

  const { submit, isLoading } = useProvisionAudiencesForm(onClose)

  const enabledAudiences = audiences.filter(
    aud => aud.plan === currentStore?.plan && tenantAudiences.some(ta => ta.slug === aud.slug)
  )

  const schema = provisionAudiencesSchema(enabledAudiences)
  const { fields, onSubmit } = useForm(schema, submit)

  const shouldSubmit = () => {
    const inputAudiences = fields.audiences.value as AvailableAudience[]
    const removedAudiences = tenantAudiences.filter(
      a => !inputAudiences.some(input => input.slug === a.slug)
    )
    const addedAudiences = inputAudiences.filter(
      input => !tenantAudiences.some(a => a.slug === input.slug)
    )
    return removedAudiences.length > 0 || addedAudiences.length > 0
  }

  const renderCheckboxes = () =>
    audiences
      .filter(aud => aud.plan === currentStore?.plan)
      .map(audience => (
        <StyledCheckbox
          key={audience.slug}
          field={{
            name: 'Audience',
            label: audience.name,
            type: 'boolean',
            value: (fields.audiences.value as Array<AvailableAudience>).some(
              a => a.slug === audience.slug
            ),
            setValue: val => {
              const updatedAudiences = val
                ? [...(fields.audiences.value as Array<AvailableAudience>), audience]
                : (fields.audiences.value as Array<AvailableAudience>).filter(
                    a => a.slug !== audience.slug
                  )
              fields.audiences.setValue(updatedAudiences)
            },
          }}
          fullWidth
        />
      ))

  return (
    <Modal onClose={onClose} title="Provision Prebuilt Audiences" width="540px">
      <form onSubmit={onSubmit} noValidate>
        <Container>
          {section === ProvisionAudiencesFormSections.Input && (
            <Section>
              <CheckboxListContainer>
                <Label>Please select one or more prebuilt audiences:</Label>
                <CheckboxList>{renderCheckboxes()}</CheckboxList>
              </CheckboxListContainer>
            </Section>
          )}

          {section === ProvisionAudiencesFormSections.Confirm && (
            <ProvisionAudiencesConfirmation fields={fields} tenantAudiences={tenantAudiences} />
          )}
        </Container>

        <Bottom>
          {section === ProvisionAudiencesFormSections.Input && (
            <>
              <Button onClick={onClose} purpose="secondary">
                Cancel
              </Button>

              <Button variant="tertiary" onClick={goToNextSection}>
                Next
              </Button>
            </>
          )}

          {section === ProvisionAudiencesFormSections.Confirm && (
            <>
              <Button variant="tertiary" onClick={goToPreviousSection}>
                Back
              </Button>

              <Button
                type="submit"
                purpose="primary"
                isLoading={isLoading}
                disabled={isLoading || !shouldSubmit()}
              >
                Provision Audiences
              </Button>
            </>
          )}
        </Bottom>
      </form>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 24px;
  margin-bottom: 70px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Label = styled.div`
  margin-bottom: 4px;
  color: ${p => p.theme.ContentSecondary};
  white-space: pre-wrap;
`

const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${p => p.theme.ComponentWorkspaceBgPage};
  border-top: 1px solid ${p => p.theme.BorderHairline};
  padding: 16px;
  z-index: 1;
  height: 70px;
  border-radius: 0 0 ${p => p.theme.CardLargeRadius} ${p => p.theme.CardLargeRadius};
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const CheckboxListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 8px;
`

const CheckboxList = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 10px;
`

const StyledCheckbox = styled(FormCheckbox)`
  width: 100%;
  flex-basis: 50%;
  margin-top: 0;
`
