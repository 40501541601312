import React from 'react'
import { Box, Card, CardActionArea, CardContent, Chip, Typography } from '@mui/material'
import { styled as MuiStyled } from '@mui/material/styles'
import { DestinationType } from 'components/chord-cdp/destinations'
import { getDestinationIcon } from './DestinationCatalog'

type DestinationCardProps = {
  destination: DestinationType
  handleSelectDestination: (destination: DestinationType) => void
}

const DestinationCard: React.FC<DestinationCardProps> = ({
  destination,
  handleSelectDestination,
}) => {
  return (
    <StyledCard
      onClick={!destination.comingSoon ? () => handleSelectDestination(destination) : undefined}
      disabled={!!destination.comingSoon}
    >
      <CardActionArea disabled={destination.comingSoon} aria-label={destination.title}>
        <CardContent>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              sx={{
                width: 36,
                height: 36,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {getDestinationIcon(destination)}
            </Box>
            {destination.comingSoon && <Chip label="Coming Soon" color="default" />}
          </Box>
          <Box>
            <Typography
              variant="h6"
              color={destination.comingSoon ? 'text.disabled' : 'textPrimary'}
            >
              {destination.title}
            </Typography>
            {destination.description && (
              <Typography
                variant="body2"
                color={destination.comingSoon ? 'text.disabled' : 'textSecondary'}
              >
                {destination.description}
              </Typography>
            )}
          </Box>
        </CardContent>
      </CardActionArea>
    </StyledCard>
  )
}

const StyledCard = MuiStyled(Card)<{ disabled: boolean }>(
  ({ theme, disabled }) => `
  border: 1px solid ${theme.palette.divider};
  &:hover {
    border-color: ${disabled ? theme.palette.divider : theme.palette.primary.main};
  }
  cursor: ${disabled ? 'not-allowed' : 'pointer'};
  width: 325px;
  height: 100%;
`
)

export default DestinationCard
