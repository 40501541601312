import React, { useEffect } from 'react'
import { H1, H2, H3, H4, H5, H6, Modal, Spinner } from '@chordco/component-library'
import styled from 'styled-components'
import Markdown from 'markdown-to-jsx'

type HelpModalProps = {
  helpFile: string
  onClose: () => void
}

const HelpModal: React.FC<HelpModalProps> = ({ helpFile, onClose }) => {
  const [markdown, setMarkdown] = React.useState('')
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState<string | null>(null)

  useEffect(() => {
    setLoading(true)
    setError(null)

    fetch(helpFile)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to load markdown file')
        }
        return response.text()
      })
      .then(text => {
        setMarkdown(text)
        setLoading(false)
      })
      .catch(error => {
        setError(error.message)
        setLoading(false)
      })
  }, [helpFile])

  return (
    <Modal
      onClose={onClose}
      title="Help & getting started"
      hideHeaderBorder={false}
      hideFooter={false}
    >
      <SectionContainer>
        {loading && (
          <SpinnerContainer>
            <Spinner scale={30} />
          </SpinnerContainer>
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {!loading && !error && (
          <Markdown
            options={{
              overrides: {
                a: {
                  component: StyledLink,
                  props: {
                    target: '_blank',
                    rel: 'noreferrer',
                  },
                },
                ul: {
                  component: StyledList,
                },
                li: {
                  component: StyledListItem,
                },
                p: {
                  component: StyledParagraph,
                },
                h1: {
                  component: H1,
                },
                h2: {
                  component: H2,
                },
                h3: {
                  component: H3,
                },
                h4: {
                  component: H4,
                },
                h5: {
                  component: H5,
                },
                h6: {
                  component: H6,
                },
                iframe: {
                  component: props => {
                    if (props.className === 'video') {
                      return (
                        <StyledVideo
                          {...props}
                          webkitAllowFullScreen
                          mozAllowFullScreen
                          allowFullScreen
                        />
                      )
                    }
                    return <iframe {...props} />
                  },
                },
                div: {
                  component: props => {
                    if (props.className === 'video-container') {
                      return <StyledVideoContainer {...props} />
                    }
                    return <div {...props} />
                  },
                },
              },
            }}
          >
            {markdown}
          </Markdown>
        )}
      </SectionContainer>
    </Modal>
  )
}

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
`

const StyledLink = styled.a`
  color: ${({ theme }) => theme.ContentAccentPrimary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const StyledParagraph = styled.p`
  margin: 8px 0;
  color: ${p => p.theme.ContentSecondary};
  white-space: pre-wrap;
  max-width: 600px;
`

const StyledList = styled.ul`
  padding-left: 16px;
`

const StyledListItem = styled.li`
  margin-bottom: 8px;
`

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.ContentAccentError};
`

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

const StyledVideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`

const StyledVideo = styled.iframe`
  width: 480px;
  height: 270px;
  border: 0;
`

export default HelpModal
