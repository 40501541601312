import React, { useState } from 'react'
import styled from 'styled-components'
import { Loading } from 'components/common'
import { DashboardEmbed } from './DashboardEmbed'
import { useHistory, useParams } from 'react-router-dom'
import { useIsMobile } from 'hooks'
import { Button, H1, H2, standardIcons } from '@chordco/component-library'
import { AvailableUserReport } from 'types'
import { LookEmbed } from './LookEmbed'
import useCanPinReport from 'redux/hooks/useCanPinReport'
import { UnpinModal } from 'components/analytics/reports/UnpinModal'
import { useReportData } from './useReportData'

export const Report: React.FC = () => {
  const { slug } = useParams<{ slug: string }>()

  const { report, isPinned, embedReportUrl, isLoading, isInitialized } = useReportData(slug)

  const isMobile = useIsMobile()
  const Heading = isMobile ? H2 : H1

  const history = useHistory()

  const canPinUnpin = useCanPinReport()

  const [showUnpinModal, setShowUnpinModal] = useState(false)

  const closeUnpinModal = () => setShowUnpinModal(false)

  const renderEmbedComponent = (
    report: AvailableUserReport | undefined,
    embedReportUrl: string | null
  ) => {
    if (!embedReportUrl || !report) {
      return null
    }

    switch (report.metricType) {
      case 'dashboard':
        return (
          <DashboardContainer>
            <DashboardEmbed url={embedReportUrl} />
          </DashboardContainer>
        )

      case 'look':
        return (
          <LookContainer>
            <LookEmbed url={embedReportUrl} />
          </LookContainer>
        )
      default:
        return null
    }
  }

  if (isLoading) {
    return <Loading />
  }

  if (isInitialized && (!report || !embedReportUrl)) {
    return (
      <Wrapper>
        <BackButton
          onClick={() => history.push('/analytics/pinned-reports')}
          icon={standardIcons.ArrowLeft}
          variant="ghost"
        >
          Back
        </BackButton>
        <Heading>Report Not Found</Heading>
        <p>The requested report could not be located. Please try another link.</p>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <BackButton
        onClick={() => history.push('/analytics/pinned-reports')}
        icon={standardIcons.ArrowLeft}
        variant="ghost"
      >
        Back
      </BackButton>
      <Header>
        <TextContainer>
          <Heading>{report?.label}</Heading>
          <SubHeading>{report?.description}</SubHeading>
        </TextContainer>
        <ButtonsContainer>
          <Button
            purpose="tertiary"
            icon={standardIcons.Unpin}
            onClick={() => setShowUnpinModal(true)}
            disabled={!canPinUnpin}
            tooltip={!canPinUnpin ? 'Please contact an admin to unpin this report' : ''}
            tooltipDirection="input-bottom-right"
          >
            Unpin
          </Button>
        </ButtonsContainer>
      </Header>

      <Container>{renderEmbedComponent(report, embedReportUrl)}</Container>

      {showUnpinModal && canPinUnpin && report && isPinned && (
        <UnpinModal
          report={report}
          onUnpin={() => {
            closeUnpinModal()

            // Redirect to pinned reports page since the report was unpinned
            history.push('/analytics/pinned-reports')
          }}
          onClose={closeUnpinModal}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100vh;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`

const BackButton = styled(Button)`
  padding-left: 0;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const SubHeading = styled.div`
  font-size: 16px;
  color: ${p => p.theme.ContentTertiary};
`

const LookContainer = styled.div`
  padding: 48px 64px 0 64px;
  height: 100%;
`

const DashboardContainer = styled.div`
  padding-bottom: 48px;
  height: 100%;
`
