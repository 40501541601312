import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useIsMobile } from 'hooks'
import { useHistory } from 'react-router'
import { useAuthData } from 'redux/state/auth'
import { Header, Section } from '../common'
import { MetricsTiles } from './MetricsTiles'
import { SystemStatus } from 'components/home/SystemStatus'
import homepageHelp from 'help/homepage.md'

export type SetupStep = {
  label: string
  description: string
  complete: boolean
  key: string
}

interface Props {
  enableSystemHealth: boolean
}

export const Home: React.FC<Props> = ({ enableSystemHealth }) => {
  const isMobile = useIsMobile()
  const history = useHistory()

  const {
    state: { user },
  } = useAuthData()

  if (!user) {
    // eslint-disable-next-line no-console
    console.error('Hub User not found! Redirecting to login...')
    history.push('/login')
    return null
  }

  const userName = useMemo(
    () => [user.firstName, user.lastName].filter(Boolean).join(' '),
    [user.firstName, user.lastName]
  )

  const title = useMemo(() => `Welcome${userName ? ` ${userName}` : ''}!`, [userName])

  const isNewUser = !user.lastSignInAt

  return (
    <>
      <Header title={title} helpFile={homepageHelp} showHelpOnPageLoad={isNewUser} />
      <Section isMobile={isMobile} hasBorder={false}>
        {isMobile ? (
          <>
            {enableSystemHealth && <SystemStatus />}
            <MetricsTiles />
          </>
        ) : (
          <FlexContainer>
            <Flex flex={2}>
              {enableSystemHealth && <SystemStatus />}
              <MetricsTiles />
            </Flex>
          </FlexContainer>
        )}
      </Section>
    </>
  )
}

export const SubSection = styled.div<{ isMobile: boolean }>`
  position: relative;
  padding-bottom: ${p => (p.isMobile ? 24 : 32)}px;
`
const FlexContainer = styled.div`
  display: flex;

  :not(:last-child) {
    margin-right: 16px;
  }
`

const Flex = styled.div<{ flex?: number }>`
  flex: ${p => p.flex || 1};

  :not(:last-child) {
    margin-right: 16px;
  }
`
