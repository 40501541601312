import React from 'react'
import { H3, Label, Tooltip } from '@chordco/component-library'
import styled from 'styled-components'
import { Box, FormControl, IconButton, MenuItem, Select } from '@mui/material'
import { DataSettingsSchedulingDays } from 'types'
import { InfoOutlined } from '@mui/icons-material'
import useUserRole from 'redux/hooks/useUserRole'

type WeekDefinitionPanelProps = {
  weekStart: string
  setWeekStart: (startOfWeek: string) => void
}

const weekDays: DataSettingsSchedulingDays[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const WeekDefinitionPanel: React.FC<WeekDefinitionPanelProps> = ({
  weekStart,
  setWeekStart,
}) => {
  const handleWeekStartChange = event => {
    setWeekStart(event.target.value)
  }

  const role = useUserRole()
  const disableMemberUser = role === 'no_role'

  return (
    <>
      <WeekDefinitionContainer>
        <TitleContainer>
          <TooltipContainer>
            <Tooltip
              text={`Change or update when a typical week begins for reporting purposes. The Chord default definition of a week is Monday through Sunday.`}
              //placement="top-start"
              direction="right"
              customWidth="300px"
            >
              <IconButton size="small">
                <InfoOutlined fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </TooltipContainer>
          <H3>Set Week Start Day</H3>
        </TitleContainer>
        <Label>Start week on </Label>
        <FormControl sx={{ minWidth: 350 }} size="small" disabled={disableMemberUser}>
          <Select id="week-start-picker" value={weekStart} onChange={handleWeekStartChange}>
            {weekDays.map(weekDay => (
              <MenuItem key={weekDay} value={weekDay}>
                {weekDay}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <BoxContainer>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}></Box>
        </BoxContainer>
      </WeekDefinitionContainer>
    </>
  )
}

const WeekDefinitionContainer = styled.div`
  position: relative;
  top: 10px;
  margin-left: 10px;
  padding-bottom: 25px;
`

const TooltipContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 190px;
`

const TitleContainer = styled.div`
  position: relative;
  top: 10px;
  padding-bottom: 20px;
`

const BoxContainer = styled.div`
  padding-top: 40px;
`
