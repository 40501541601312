import { useLookerData } from 'redux/state/looker'
import { useState } from 'react'
import { PayloadAction } from '@reduxjs/toolkit'
import { ActivateAudienceResponse } from 'types'
import useMixpanel from 'hooks/useMixpanel'
import { MixpanelEventType } from 'utils/mixpanel/types'

interface ActivateAudienceData {
  audienceName: string
  audienceDescription: string
  audienceId: string
}

export const useActivateAudience = (onActivate: () => void) => {
  const { activateAudience } = useLookerData()
  const { trackEvent } = useMixpanel()
  const [isLoading, setIsLoading] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const [hasSucceeded, setHasSucceeded] = useState(false)

  const activate = async ({
    audienceName,
    audienceDescription,
    audienceId,
  }: ActivateAudienceData) => {
    if (!activateAudience) return

    setIsLoading(true)
    setHasSucceeded(false)
    setHasFailed(false)

    try {
      const response = (await activateAudience(
        audienceName,
        audienceDescription,
        audienceId
      )) as PayloadAction<{ data: ActivateAudienceResponse }>

      if (response.payload.data?.audience) {
        setHasSucceeded(true)
        onActivate()
        trackEvent(MixpanelEventType.AudienceActivated, {
          'Audience Name': audienceName,
          'Page Name': 'Audiences',
        })
      } else {
        setHasFailed(true)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error: ', error)
      setHasFailed(true)
    } finally {
      setIsLoading(false)
    }
  }

  return { activate, isLoading, hasSucceeded, hasFailed }
}
