import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import DataAudiences from 'assets/DataAudiences.svg'
import { Button, Spinner } from '@chordco/component-library'
import { Tenant, User } from 'types'
import AccessRequestConfirmed from './AccessRequestConfirmed'
import {
  CenteredContent,
  ImageContainer,
  StyleBody,
  StyledH1,
  LoadingContainer,
} from './Common'
import { useNotion } from 'hooks/useNotion'

type Props = {
  tenant: Tenant
  user: User
}

export default function EmptyAudiences({ tenant, user }: Props) {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const { createFeatureAccessRequest, fetchFeatureAccessRequests } = useNotion()

  const handleClick = async () => {
    try {
      await createFeatureAccessRequest({
        tenantId: tenant.id,
        userName: `${user.firstName} ${user.lastName}`,
        userEmail: user.email ?? 'n/a',
        feature: 'data_audiences',
      })

      setShowConfirmation(true)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error creating feature access request', error)
    }
  }

  useEffect(() => {
    const fetchAccessRequest = async () => {
      try {
        setIsFetching(true)

        const response = await fetchFeatureAccessRequests(
          tenant.id,
          'data_audiences'
        )

        if (response.results.length > 0) {
          setShowConfirmation(true)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching feature access requests', error)
      } finally {
        setIsFetching(false)
      }
    }

    fetchAccessRequest()
  }, [fetchFeatureAccessRequests, tenant.id])

  return (
    <Wrapper>
      <CenteredContent gap={48}>
        <ImageContainer>
          <img src={DataAudiences} alt="Audiences" />
        </ImageContainer>

        <TextCtaContainer>
          <StyledH1>Build and Leverage Audiences Effortlessly</StyledH1>
          <StyleBody>
            Create and manage tailored audience segments effortlessly with our
            pre-built options and upcoming audience builder tools to refine your
            marketing strategy.
          </StyleBody>

          {isFetching && (
            <LoadingContainer>
              <Spinner scale={30} />
            </LoadingContainer>
          )}

          {!isFetching && (
            <>
              {showConfirmation ? (
                <AccessRequestConfirmed />
              ) : (
                <Button onClick={handleClick} name="Request Early Access" location="Audiences">
                  Request Early Access
                </Button>
              )}
            </>
          )}
        </TextCtaContainer>
      </CenteredContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-top: 24px;
`

const TextCtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`
