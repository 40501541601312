/* eslint-disable no-console */
import { useEffect } from 'react'
import { User } from 'types'
import { EnvVarConfig } from 'utils/envVarConfig'

declare global {
  interface Window {
    Beacon: any
  }
}

type HelpScoutProps = {
  user?: User
  onClose?: () => void
}

function useHelpScout({ user, onClose }: HelpScoutProps) {
  const helpscoutDomain = 'https://beacon-v2.helpscout.net'
  const helpscoutId = EnvVarConfig.REACT_APP_HELPSCOUT_BEACON_ID

  // prettier-ignore
  const loadScript = () => {
    if (window.Beacon) return false;

    (function (e, t, n) {
      function a() {
        const e = t.getElementsByTagName('script')[0],
          n = t.createElement('script')
        ;(n.async = !0), (n.src = helpscoutDomain), e.parentNode?.insertBefore(n, e)
      }

      if (
        ((e.Beacon = n =
          function (t, n, a) {
            e.Beacon.readyQueue.push({ method: t, options: n, data: a })
          }),
        (n.readyQueue = []),
        'complete' === t.readyState)
      ) {
        return a()
      }
      e.addEventListener('load', a, !1)

      // eslint-disable-next-line @typescript-eslint/no-empty-function
    })(window, document, window.Beacon || function () {})
    return true
  }

  useEffect(() => {
    const initializeBeacon = async () => {
      try {
        await loadScript()
        window.Beacon('config', {
          mode: 'askFirst',
          docsEnabled: true,
          color: '#1A1E20',
          display: {
            style: 'manual',
          },
        })
        window.Beacon('init', helpscoutId)
        window.Beacon('on', 'close', () => {
          if (onClose) {
            onClose()
          }
        })
      } catch (error) {
        console.error(error)
      }
    }

    if (helpscoutId) {
      initializeBeacon()
    } else {
      console.error('HelpScout Beacon ID not found.')
    }

    return () => {
      if (window.Beacon) {
        window.Beacon('destroy')
      }
    }
  }, [helpscoutId])

  useEffect(() => {
    if (user && window.Beacon) {
      window.Beacon('identify', {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email ?? '',
        company: user.company?.name ?? '',
      })
    }
  }, [user])

  const startChat = () => {
    if (window.Beacon) {
      window.Beacon('open')
    } else {
      console.error('HelpScout Beacon is not loaded.')
    }
  }

  const stopChat = () => {
    if (window.Beacon) {
      window.Beacon('close')
    } else {
      console.error('HelpScout Beacon is not loaded.')
    }
  }

  const suggestLinks = (links: { label: string; url: string }[]) => {
    if (window.Beacon) {
      const articles = links.map(link => ({
        url: link.url,
        text: link.label,
      }))
      window.Beacon('suggest', articles)
    } else {
      console.error('HelpScout Beacon is not loaded.')
    }
  }

  return [startChat, stopChat, suggestLinks] as const
}

export default useHelpScout
