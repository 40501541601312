import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTenantsData } from 'redux/state/tenants'
import { useEnvironmentsData } from 'redux/state/environments'
import { useConfigSheetsData } from 'redux/state/configSheets'
import { Spinner, SimpleBar, Button } from '@chordco/component-library'
import { useIsLoading } from 'redux/utils'
import { Header, Section } from '../common'
import { ConfigSheetCard } from './SheetCard'
import { useIsMobile } from 'hooks'
import EmptySheets from './EmptySheets'
import useUserRole from 'redux/hooks/useUserRole'
import { Plus } from '@chordco/component-library/components/icons/standardIcons/Plus'
import { UpdateConfigForm } from 'components/provisioning/UpdateConfigForm'
import { useProvisioningData } from 'redux/state/provisioning'
import modelAmplificationsHelp from 'help/model_amplifications.md'

const ConfigSheets: React.FC = () => {
  const isMobile = useIsMobile()

  const role = useUserRole()

  const isCurrentUserSuperUser = role === 'superuser'

  const [showConfigUiForm, setShowConfigUiForm] = useState(false)

  const {
    state: { currentTenant, currentStore },
  } = useTenantsData()

  const {
    state: { selectedEnvironment },
  } = useEnvironmentsData()

  if (!currentTenant) throw new Error('No current tenant')
  if (!currentStore) throw new Error('currentStore is undefined')
  if (!selectedEnvironment) throw new Error('No selected environment')

  const {
    state: { activeSheets },
    getSheets,
  } = useConfigSheetsData()

  const {
    state: { configSheets },
    getConfigSheets,
  } = useProvisioningData()

  useEffect(() => {
    const fetchSheetsAsync = async () => {
      if (!isLoadingSheets) {
        getSheets && (await getSheets())
      }

      if (isCurrentUserSuperUser && !isLoadingConfigSheets) {
        await getConfigSheets()
      }
    }

    fetchSheetsAsync()
  }, [isCurrentUserSuperUser])

  const isLoadingSheets = useIsLoading('getSheets')
  const isLoadingConfigSheets = useIsLoading('getConfigSheets')
  const loading = isLoadingSheets || isLoadingConfigSheets

  return loading ? (
    <Spinner scale={30} />
  ) : (
    <>
      <Header
        title="Model Amplifications"
        subtitle="Manage all your model amplifications in one place"
        helpFile={modelAmplificationsHelp}
      >
        {isCurrentUserSuperUser ? (
          <ProvisionSheetsButton
            icon={Plus}
            variant="tertiary"
            onClick={() => setShowConfigUiForm(true)}
            name="Configure"
            location="Model Amplifications"
          >
            Configure
          </ProvisionSheetsButton>
        ) : null}
      </Header>
      <SimpleBar>
        <Section isMobile={isMobile} hasBorder={false}>
          {loading && <Spinner scale={30} />}

          {!loading && (
            <>
              {activeSheets.length > 0 ? (
                activeSheets.map(sheet => <ConfigSheetCard key={sheet.id} sheet={sheet} />)
              ) : (
                <EmptySheets configSheets={configSheets} />
              )}
            </>
          )}
        </Section>
      </SimpleBar>

      {showConfigUiForm && configSheets && (
        <UpdateConfigForm
          onClose={() => setShowConfigUiForm(false)}
          activeSheets={activeSheets}
          configSheets={configSheets}
        />
      )}
    </>
  )
}

const ProvisionSheetsButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 100px; // move slightly to the left of the help button sitting in the top right corner
`

export default ConfigSheets
