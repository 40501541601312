import { hubClient } from '../http'
import {
  ActivateAudienceResponse,
  CopyAudienceResponse,
  CopyDashboardResponse,
  CopyLookResponse,
  CreateDashboardResponse,
  AvailableAudiences,
  AvailableReports,
  AvailableUserAudiences,
  AvailableUserReports,
  AvailableQuickstarts,
  GetEmbedUrlResponse,
  SharedDashboardsResponse,
  SharedLooksResponse,
  GetTilesRequest,
  GetTilesResponse,
  ImportDashboardResponse,
  ImportLookResponse,
  LookerEmbedUser,
  LookerReportType,
  MetricsLookupIds,
  PinDashboardResponse,
  PinLookResponse,
  PublishDashboardResponse,
  PublishLookResponse,
  UnpinDashboardResponse,
  UnpinLookResponse,
  GetEmbedUserBootstrapURLResponse,
  AvailableUserAudience,
} from 'types'

export interface LookerClient {
  getAvailableReports: (data: { tenantId: number; storeId: number }) => Promise<AvailableReports>

  getAvailableUserReports: (data: {
    tenantId: number
    storeId: number
  }) => Promise<AvailableUserReports>

  getAvailableAudiences: (data: {
    tenantId: number
    storeId: number
  }) => Promise<AvailableAudiences>

  getAvailableUserAudiences: (data: { tenantId: number }) => Promise<AvailableUserAudiences>

  getAvailableQuickstarts: (data: {
    tenantId: number
    storeId: number
    forceRefresh: boolean
  }) => Promise<AvailableQuickstarts>

  getEmbedUrl: (data: {
    tenantId: number
    storeId: number
    slug: string
    type: LookerReportType
    theme?: string
  }) => Promise<GetEmbedUrlResponse>

  getEmbedUserUrl: (data: {
    tenantId: number
    storeId: number
    slug: string
    type: LookerReportType
    theme?: string
  }) => Promise<GetEmbedUrlResponse>

  getEmbedAudienceUrl: (data: {
    tenantId: number
    slug: string
    type: LookerReportType
    theme?: string
  }) => Promise<GetEmbedUrlResponse>

  getEmbedUserAudienceUrl: (data: {
    tenantId: number
    slug: string
    theme?: string
  }) => Promise<GetEmbedUrlResponse>

  getEmbedQuickstartUrl: (data: {
    tenantId: number
    storeId: number
    slug: string
    type: LookerReportType
    theme?: string
  }) => Promise<GetEmbedUrlResponse>

  runLook: (data: { tenantId: number; storeId: number; lookId: number }) => Promise<any>

  getTiles: (data: GetTilesRequest) => Promise<GetTilesResponse[]>

  getMetrics: (data: {
    tenantId: number
    storeId: number
    metricIds: (MetricsLookupIds | number)[]
  }) => Promise<GetTilesResponse[]>

  createDashboard: (data: {
    tenantId: number
    storeId: number
    title: string
    description: string
    folderId: string
  }) => Promise<CreateDashboardResponse>

  getEmbedUser: () => Promise<LookerEmbedUser>

  createEmbedUser: () => Promise<LookerEmbedUser>

  importReport: (data: {
    tenantId: number
    storeId: number
    slug: string
    folderId: string
    metricType: LookerReportType
  }) => Promise<ImportDashboardResponse | ImportLookResponse>

  copyDashboard: (data: {
    tenantId: number
    storeId: number
    dashboardId: string
    dashboardTitle: string
    dashboardDescription: string
    folderId: string
    reportType: LookerReportType
  }) => Promise<CopyDashboardResponse>

  copyLook: (data: {
    tenantId: number
    storeId: number
    lookId: string
    lookTitle: string
    lookDescription: string
    folderId: string
  }) => Promise<CopyLookResponse>

  publishDashboard: (data: {
    tenantId: number
    storeId: number
    dashboardName: string
    dashboardDescription: string
    dashboardSlug: string
    replaceExisting: boolean
  }) => Promise<PublishDashboardResponse>

  pinDashboard: (data: {
    tenantId: number
    storeId: number
    dashboardId: string
  }) => Promise<PinDashboardResponse>

  unpinDashboard: (data: {
    tenantId: number
    storeId: number
    dashboardSlug: string
  }) => Promise<UnpinDashboardResponse>

  publishLook: (data: {
    tenantId: number
    storeId: number
    lookName: string
    lookDescription: string
    lookSlug: string
    replaceExisting: boolean
  }) => Promise<PublishLookResponse>

  pinLook: (data: { tenantId: number; storeId: number; lookId: string }) => Promise<PinLookResponse>

  unpinLook: (data: {
    tenantId: number
    storeId: number
    lookSlug: string
  }) => Promise<UnpinLookResponse>

  copyAudience: (data: {
    tenantId: number
    audienceId: string
    audienceTitle: string
    audienceDescription: string
    folderId: string
  }) => Promise<CopyAudienceResponse>

  activateAudience: (data: {
    tenantId: number
    audienceId: string
    audienceName: string
    audienceDescription: string
  }) => Promise<ActivateAudienceResponse>

  provisionAudiences: (data: {
    tenantId: number
    audienceIds: string[]
  }) => Promise<AvailableUserAudience[]>

  getSharedDashboards: (data: {
    tenantId: number
    storeId: number
  }) => Promise<SharedDashboardsResponse>

  getSharedLooks: (data: { tenantId: number; storeId: number }) => Promise<SharedLooksResponse>

  getEmbedUserBootstrapURL: (data: {
    tenantId: number
    storeId: number
  }) => Promise<GetEmbedUserBootstrapURLResponse>
}

export const lookerClient: LookerClient = {
  getAvailableReports: ({ tenantId, storeId }) =>
    hubClient.get(`tenants/${tenantId}/stores/${storeId}/metrics`),

  getAvailableUserReports: ({ tenantId, storeId }) =>
    hubClient.get(`tenants/${tenantId}/stores/${storeId}/metrics/user_metrics`),

  getAvailableAudiences: ({ tenantId, storeId }) =>
    hubClient.get(`tenants/${tenantId}/stores/${storeId}/audiences`),

  getAvailableUserAudiences: ({ tenantId }) =>
    hubClient.get(`tenants/${tenantId}/audiences/user_audiences`),

  getAvailableQuickstarts: ({ tenantId, storeId, forceRefresh }) =>
    hubClient.get(
      `tenants/${tenantId}/stores/${storeId}/metrics/templates?force_refresh=${forceRefresh}`
    ),

  getEmbedUrl: ({ tenantId, storeId, slug, type, theme }) =>
    hubClient.get(`tenants/${tenantId}/stores/${storeId}/metrics/embed`, {
      params: { slug, metricType: type, theme },
    }),

  getEmbedUserUrl: ({ tenantId, storeId, slug, type, theme }) =>
    hubClient.get(`tenants/${tenantId}/stores/${storeId}/metrics/embed_report`, {
      params: { slug, metricType: type, theme },
    }),

  getEmbedAudienceUrl: ({ tenantId, slug, theme }) =>
    hubClient.get(`tenants/${tenantId}/audiences/embed`, {
      params: { slug, theme, audience_type: 'prebuilt' },
    }),

  getEmbedUserAudienceUrl: ({ tenantId, slug, theme }) =>
    hubClient.get(`tenants/${tenantId}/audiences/embed`, {
      params: { slug, theme, audience_type: 'user' },
    }),

  getEmbedQuickstartUrl: ({ tenantId, storeId, slug, type, theme }) =>
    hubClient.get(`tenants/${tenantId}/stores/${storeId}/metrics/embed_report`, {
      params: { slug, metricType: type, theme },
    }),

  getEmbedUserBootstrapURL: ({ tenantId, storeId }) =>
    hubClient.get(`tenants/${tenantId}/stores/${storeId}/metrics/embed_user_bootstrap_url`),

  runLook: ({ tenantId, storeId, lookId }) =>
    hubClient.get(`tenants/${tenantId}/stores/${storeId}/metrics/run/${lookId}`),

  getTiles: ({ tenantId, storeId, timePeriod }) =>
    hubClient.get(
      `/tenants/${tenantId}/stores/${storeId}/metrics/overview?time_period=${timePeriod}`
    ),

  getMetrics: ({ tenantId, storeId, metricIds }) =>
    hubClient.get(`tenants/${tenantId}/stores/${storeId}/metrics/metric`, {
      params: { metric_id: metricIds },
    }),

  createDashboard: ({ tenantId, storeId, title, description, folderId }) =>
    hubClient.post(`tenants/${tenantId}/stores/${storeId}/metrics/create_dashboard`, {
      title,
      description,
      folder_id: folderId,
    }),

  getEmbedUser: () => hubClient.get(`looker_users/find_user`),
  createEmbedUser: () => hubClient.post(`looker_users/create_user`),

  importReport: ({ tenantId, storeId, slug, folderId, metricType }) =>
    hubClient.post(`tenants/${tenantId}/stores/${storeId}/metrics/import_report`, {
      slug: slug,
      folder_id: folderId,
      metric_type: metricType,
    }),

  copyDashboard: ({
    tenantId,
    storeId,
    dashboardId,
    dashboardTitle,
    dashboardDescription,
    folderId,
    reportType,
  }) =>
    hubClient.post(`tenants/${tenantId}/stores/${storeId}/metrics/copy_dashboard`, {
      dashboard_id: dashboardId,
      dashboard_title: dashboardTitle,
      dashboard_description: dashboardDescription,
      folder_id: folderId,
      is_lookml_dashboard: reportType === 'lookml_dashboard',
    }),

  copyLook: ({ tenantId, storeId, lookId, lookTitle, lookDescription, folderId }) =>
    hubClient.post(`tenants/${tenantId}/stores/${storeId}/metrics/copy_look`, {
      look_id: lookId,
      look_title: lookTitle,
      look_description: lookDescription,
      folder_id: folderId,
    }),

  publishDashboard: ({
    tenantId,
    storeId,
    dashboardName,
    dashboardDescription,
    dashboardSlug,
    replaceExisting,
  }) =>
    hubClient.post(`tenants/${tenantId}/stores/${storeId}/metrics/publish_dashboard`, {
      dashboard_name: dashboardName,
      dashboard_description: dashboardDescription,
      dashboard_slug: dashboardSlug,
      replace: replaceExisting,
    }),

  pinDashboard: ({ tenantId, storeId, dashboardId }) =>
    hubClient.post(`tenants/${tenantId}/stores/${storeId}/metrics/pin_dashboard`, {
      dashboard_id: dashboardId,
    }),

  unpinDashboard: ({ tenantId, storeId, dashboardSlug }) =>
    hubClient.post(`tenants/${tenantId}/stores/${storeId}/metrics/unpin_dashboard`, {
      dashboard_slug: dashboardSlug,
    }),

  publishLook: ({ tenantId, storeId, lookName, lookDescription, lookSlug, replaceExisting }) =>
    hubClient.post(`tenants/${tenantId}/stores/${storeId}/metrics/publish_look`, {
      look_name: lookName,
      look_description: lookDescription,
      look_slug: lookSlug,
      replace: replaceExisting,
    }),

  pinLook: ({ tenantId, storeId, lookId }) =>
    hubClient.post(`tenants/${tenantId}/stores/${storeId}/metrics/pin_look`, {
      look_id: lookId,
    }),

  unpinLook: ({ tenantId, storeId, lookSlug }) =>
    hubClient.post(`tenants/${tenantId}/stores/${storeId}/metrics/unpin_look`, {
      look_slug: lookSlug,
    }),

  getSharedDashboards: ({ tenantId, storeId }) =>
    hubClient.get(`tenants/${tenantId}/stores/${storeId}/metrics/shared_dashboards`),

  getSharedLooks: ({ tenantId, storeId }) =>
    hubClient.get(`tenants/${tenantId}/stores/${storeId}/metrics/shared_looks`),

  copyAudience: ({ tenantId, audienceId, audienceTitle, audienceDescription, folderId }) =>
    hubClient.post(`tenants/${tenantId}/audiences/copy_audience`, {
      audience_id: audienceId,
      audience_title: audienceTitle,
      audience_description: audienceDescription,
      folder_id: folderId,
    }),

  activateAudience: ({ tenantId, audienceId, audienceName, audienceDescription }) =>
    hubClient.post(`tenants/${tenantId}/audiences/activate_audience`, {
      audience_id: audienceId,
      audience_name: audienceName,
      audience_description: audienceDescription,
    }),

  provisionAudiences: ({ tenantId, audienceIds }) =>
    hubClient.post(`tenants/${tenantId}/audiences/provision_audiences`, {
      audience_ids: audienceIds,
    }),
}
