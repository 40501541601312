import React from 'react'
import { Button, H6, standardIcons } from '@chordco/component-library'
import { StyledCaption } from 'components/settings/integrations/requests/Common'
import styled from 'styled-components'
const { AlertTriangle, CheckCircle } = standardIcons

interface ModelAmplificationsActivationNoticeProps {
  activationButtonDisabled: boolean
  onActivationRequested: () => void
}

export function ModelAmplificationsActivationNotice({
  onActivationRequested,
  activationButtonDisabled,
}: ModelAmplificationsActivationNoticeProps) {
  return (
    <Container>
      <AlertTriangle />
      <Content>
        <H6>Actions Required</H6>
        <StyledCaption>Fill out your data and request Activation</StyledCaption>
      </Content>
      <ButtonContainer>
        <Button
          variant="outlined"
          purpose="tertiary"
          size="small"
          onClick={onActivationRequested}
          disabled={activationButtonDisabled}
          name="Request Activation"
          location="Model Amplifications"
        >
          <H6>Request Activation</H6>
        </Button>
      </ButtonContainer>
    </Container>
  )
}

export function ModelAmplificationsActivationConfirmation() {
  return (
    <Container isConfirmation>
      <CheckCircle />
      <Content>
        <H6>Activation request received</H6>
        <StyledCaption>
          The Chord data team is on it and will activate this configuration within 48hours.
        </StyledCaption>
      </Content>
    </Container>
  )
}

const Container = styled.div<{ isConfirmation?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${p => (p.isConfirmation ? 'flex-start' : 'space-between')};
  align-items: center;
  height: 56px;
  background-color: ${p =>
    p.isConfirmation ? p.theme.BgAccentLightAADarkSuccess : p.theme.BgAccentLightAADarkWarning};
  border-radius: 8px;
  gap: 20px;
  padding: 0 20px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonContainer = styled.div`
  margin-left: auto;
`
