import React from 'react'
import { Header, Section } from 'components/common'
import { SimpleBar, Spinner } from '@chordco/component-library'
import { useIsMobile } from 'hooks'
import { useTenantsData } from 'redux/state/tenants'
import { useAuthData } from 'redux/state/auth'
import { useIntegrationsByEnvironment } from 'redux/hooks'
import { useIsLoading } from 'redux/utils'
import { FivetranProvider } from 'components/settings/integrations/forms/fivetran/FivetranContext'
import { MyDataSources } from 'components/settings/integrations/forms/fivetran/components'
import EmptySources from 'components/settings/integrations/requests/EmptySources'
import dataSourcesHelp from 'help/data_sources.md'

export const DataSources = () => {
  const isMobile = useIsMobile()
  const { integrations } = useIntegrationsByEnvironment()

  const {
    state: { currentTenant, currentStore },
  } = useTenantsData()

  const {
    state: { user },
  } = useAuthData()

  if (!user) throw new Error('Hub User not found!')
  if (!currentTenant) throw new Error('No current tenant')
  if (!currentStore) throw new Error('currentStore is undefined')

  const integration = integrations.find(
    i => i.type === 'Hub::Fivetran' && i.active
  )

  const { destinationGroupId, filterConnectors, fivetranConnectors } =
    integration?.settings || {}

  const tenantHasConnector = (slug: string) => {
    if (!filterConnectors) return false

    return filterConnectors
      .split(',')
      .map(c => c.trim())
      .includes(slug)
  }

  // Filter out the connectors that are inactive and not configured for the current tenant.
  const activeConnectors = fivetranConnectors?.filter(
    c => c.active && tenantHasConnector(c.slug)
  )

  const loading = useIsLoading('getStoreSetup')

  return (
    <>
      <Header
        title="Data Sources"
        subtitle="Data sources connected to your Chord instance"
        helpFile={dataSourcesHelp}
      />
      <SimpleBar>
        <Section isMobile={isMobile} hasBorder={false}>
          {loading && <Spinner scale={30} />}

          {!loading && !destinationGroupId && <EmptySources tenant={currentTenant} user={user} />}

          {!loading && destinationGroupId && (
            <FivetranProvider
              destinationGroupId={destinationGroupId}
              activeConnectors={activeConnectors}
              fivetranConnectors={fivetranConnectors}
            >
              <MyDataSources />
            </FivetranProvider>
          )}
        </Section>
      </SimpleBar>
    </>
  )
}
