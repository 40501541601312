import React, { useEffect, useState } from 'react'
import { DatePicker } from './DatePicker'
import moment from 'moment'
import { Input } from '../inputs'
import { standardIcons } from '../icons'

interface Props {
  date: moment.Moment | undefined
  onChange: Function
  isDayBlocked?: (date: moment.Moment) => boolean
  hasInput?: boolean
  inputProps?: {}
  hideIcon?: boolean
  placeholder?: string
}

const { Calendar } = standardIcons

export const DatePickerWithInput: React.FC<Props> = ({
  date,
  onChange,
  isDayBlocked,
  hasInput,
  inputProps = {},
  hideIcon = false,
  placeholder,
}) => {
  const [inputValue, setInputValue] = useState(
    date ? moment(date).format('M/D/YYYY') : ''
  )

  useEffect(() => {
    if (inputValue.length === 0) {
      onChange('')
      return
    }

    if (inputValue.length < 6) return

    const date = moment(inputValue, [
      'MM-DD-YY',
      'MM-DD-YYYY',
      'MM/DD/YY',
      'MM/DD/YYYY',
    ])

    if (date.isValid() && date.year() > 2000) {
      onChange(date.toString())
    }
  }, [inputValue])

  useEffect(() => {
    const momentDate = moment(date)
    if (date && momentDate.isValid()) {
      setInputValue(momentDate.format('M/D/YYYY'))
    }
  }, [date])

  const onDateChange = updatedDate => {
    setInputValue(updatedDate.format('M/D/YYYY'))
    onChange(updatedDate)
  }

  return (
    <DatePicker
      date={date ? moment(date) : null}
      onChange={onDateChange}
      isDayBlocked={isDayBlocked}
      hasInput={hasInput}
      placeholder={placeholder}
      control={
        <Input
          {...inputProps}
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          leftIcon={hideIcon ? undefined : Calendar}
        />
      }
    />
  )
}
