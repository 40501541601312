import React, { useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { Button, standardIcons } from '@chordco/component-library'
import { AvailableUserAudience } from 'types'
import { UserAudienceEmbed } from './UserAudienceEmbed'
import { ActivateAudienceModal } from './ActivateAudienceModal'
import { FormControl, Select, SelectChangeEvent, MenuItem } from '@mui/material'
import useMixpanel from 'hooks/useMixpanel'
import { MixpanelEventType } from 'utils/mixpanel/types'

type MyAudiencesProps = {
  slug?: string
  options: AvailableUserAudience[]
  onDeleteAudience: () => void
  onActivateAudience: () => void
}

const getLinkFromSlug = (slug: string) => `/audiences/my-audiences/${slug}`

export const MyAudiences: React.FC<MyAudiencesProps> = ({
  slug,
  options,
  onDeleteAudience,
  onActivateAudience,
}) => {
  const [showActivateModal, setShowActivateModal] = useState(false)
  const { trackEvent } = useMixpanel()
  const dropdownOptions = options.map(o => ({
    id: o.id,
    label: o.label,
  }))

  const selectedOption = !slug ? options[0] : options.find(o => o.slug === slug)

  if (!selectedOption) return null

  const history = useHistory()

  return (
    <Wrapper>
      <Top>
        <FormControl sx={{ minWidth: 300 }} size="small">
          <Select
            value={selectedOption.slug}
            onChange={(event: SelectChangeEvent<string>) => {
              history.push(getLinkFromSlug(event.target.value))

              const selected = options.find(o => o.id === event.target.value)
              if (!selected) return
              trackEvent(MixpanelEventType.FilterClicked, {
                'Filter Name': selected.label,
                'Page Name': 'Audiences',
              })
            }}
          >
            {dropdownOptions.length > 0 &&
              dropdownOptions.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}

            {dropdownOptions.length === 0 && (
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            )}
          </Select>
        </FormControl>

        <RightButtons>
          <Button
            purpose="primary"
            icon={standardIcons.DataActivations}
            onClick={() => setShowActivateModal(true)}
            name={`Activate Audience ${selectedOption.label}`}
            location="Audiences"
          >
            Activate Audience
          </Button>
          <Button
            purpose="secondary"
            icon={standardIcons.Template}
            onClick={() => history.push('/audiences/prebuilt-audiences')}
            name="Browse Templates"
            location="Audiences"
          >
            Browse Templates
          </Button>
        </RightButtons>
      </Top>
      <EmbedContainer>
        <UserAudienceEmbed slug={selectedOption.slug} onDeleteAudience={onDeleteAudience} />
      </EmbedContainer>

      {showActivateModal && (
        <ActivateAudienceModal
          audienceName={selectedOption.label}
          audienceId={selectedOption.id}
          onClose={() => setShowActivateModal(false)}
          onActivate={() => {
            setShowActivateModal(false)
            onActivateAudience()
          }}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100vh;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
`

const RightButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const EmbedContainer = styled.div`
  border-radius: 0 0 0 ${p => p.theme.PageRadius};
  border-top: 1px solid ${p => p.theme.BorderOptimal};
  height: 100%;
  padding: 16px 0;
`
