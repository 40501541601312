import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { useGoogleSheets } from './SheetsContext'
import { Loading } from 'components/common'
import {
  ColumnHeader,
  ConfigurationSheet,
  ValidationRuleType,
} from 'api/google_sheets/types'

import { Button, Chip, H3, standardIcons } from '@chordco/component-library'
const { ArrowLeft } = standardIcons

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers'

import {
  MRT_Row,
  MRT_RowData,
  MRT_ColumnDef,
  MRT_TableOptions,
  MRT_PaginationState,
} from 'material-react-table'

import Table from 'components/config_sheets/Table/Table'
import TableHeader from 'components/config_sheets/Table/TableHeader'
import TableDeleteRowDialog from 'components/config_sheets/Table/TableDeleteRowDialog'
import ApiErrorDialog from 'components/config_sheets/ApiErrorDialog'

import {
  validateDateTime,
  validateNumber,
  validateRequired,
} from './ValidationErrors'
import { DATE_FORMAT } from './Constants'
import { SheetValidationError } from './Types'
import moment, { Moment } from 'moment'

import { useHistory } from 'react-router-dom'
import useUserRole from 'redux/hooks/useUserRole'
import { IntegrityCheckModal } from './IntegrityCheckModal'
import { ImportCSVSheetModal } from './ImportCSVSheetModal'
import { ClearSheetDataModal } from './ClearSheetDataModal'
import { DownloadSheetModal } from './DownloadSheetModal'
import { IntegrityCheckResultsModal } from './IntegrityCheckResultsModal'
import { IntegrityCheckCancelModal } from './IntegrityCheckCancelModal'
import { SheetUploadFeedback } from './SheetUploadFeedback'

import { configSheetState } from 'hooks/useModelAmplificationsActivationHelper'
import {
  ModelAmplificationsActivationNotice,
  ModelAmplificationsActivationConfirmation,
} from './ModelAmplificationActivationBanners'
import { useExperiments } from 'hooks'
import { useTenantsData } from 'redux/state/tenants'

interface DuplicateErrorRow {
  index: number
  values: string[]
}

export interface DuplicateError {
  reason: string
  duplicates: {
    rows: DuplicateErrorRow[]
    headers: ColumnHeader[]
  }
}

export type SheetApiError = string | DuplicateError

const ConfigSheet = <T extends MRT_RowData>({ slug }: { slug: string }) => {
  const {
    state: { currentTenant },
    createModelAmplificationRequest,
  } = useTenantsData()

  const modelAmplificationsGA = useExperiments('hub_model_amplifications_ga')

  const role = useUserRole()
  const enableEditing = role === 'admin' || role === 'data_admin' || role === 'superuser'

  const history = useHistory()

  const { googleSheetsClient } = useGoogleSheets()

  const [isLoading, setIsLoading] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const [processingSheetUpload, setProcessingSheetUpload] = useState(false)
  const [showImportCSVModal, setShowImportCSVModal] = useState(false)
  const [showClearDataModal, setshowClearDataModal] = useState(false)
  const [showDownloadSheetModal, setShowDownloadSheetModal] = useState(false)
  const [showIntegrityCheckModal, setShowIntegrityCheckModal] = useState(false)
  const [showIntegrityCheckResultsModal, setShowIntegrityCheckResultsModal] = useState(false)
  const [showIntegrityCheckCancelModal, setShowIntegrityCheckCancelModal] = useState(false)

  const [rowCount, setRowCount] = useState(0)
  const [integrityCheckProgress, setIntegrityCheckProgress] = useState(0)
  const [integrityCheckRowsUpdated, setIntegrityCheckRowsUpdated] = useState(0)

  const [controller, setController] = useState<AbortController | null>(null)

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  })

  const [sheet, setSheet] = useState<ConfigurationSheet<T>>()

  const [validationErrors, setValidationErrors] = useState<SheetValidationError>({})

  const [apiError, setApiError] = useState<SheetApiError>()

  const [showDeleteRowModal, setShowDeleteRowModal] = useState<MRT_Row<T>>()

  const configSheetStateDetails = configSheetState(sheet?.data?.provisionState)

  function createSelectColumn<T extends MRT_RowData>(header: ColumnHeader): MRT_ColumnDef<T> {
    const { name, key, note, required, validationRule } = header

    return {
      accessorKey: key,
      header: name,
      editVariant: 'select',
      editSelectOptions: validationRule?.allowedValues ?? [],
      muiEditTextFieldProps: {
        select: true,
        required: required,
        error: !!validationErrors[key],
        helperText: validationErrors[key] ?? note,
        InputProps: {
          sx: {
            textTransform: 'none',
          },
        },
      },
      Header: ({ column }) => {
        return <TableHeader title={column.columnDef.header} note={note} required={required} />
      },
    }
  }

  function createTextColumn<T extends MRT_RowData>(header: ColumnHeader): MRT_ColumnDef<T> {
    const { name, key, note, required } = header
    return {
      accessorKey: key,
      header: name,
      Header: ({ column }) => {
        return <TableHeader title={column.columnDef.header} note={note} required={required} />
      },
      muiEditTextFieldProps: {
        type: 'text',
        required: required,
        error: !!validationErrors[key],
        helperText: validationErrors[key] ?? note,
        InputProps: {
          sx: {
            textTransform: 'none',
          },
        },
        onFocus: () =>
          setValidationErrors({
            ...validationErrors,
            [key]: undefined,
          }),
        onChange: e => {
          const value = e.target.value
          if (!validateRequired(value)) {
            setValidationErrors({
              ...validationErrors,
              [key]: 'This field is required',
            })
          } else {
            setValidationErrors({
              ...validationErrors,
              [key]: undefined,
            })
          }
        },
      },
    }
  }

  function createDateColumn<T extends MRT_RowData>(header: ColumnHeader): MRT_ColumnDef<T> {
    const { name, key, note, required } = header
    return {
      accessorKey: key,
      header: name,
      sortingFn: 'datetime',
      muiEditTextFieldProps: {
        required: required,
      },
      Header: ({ column }) => {
        return <TableHeader title={column.columnDef.header} note={note} required={required} />
      },
      Cell: ({ cell }) => {
        const value = cell.getValue() as string
        if (validateDateTime(value)) {
          return <>{moment(value).format(DATE_FORMAT)}</>
        } else {
          return <>{value}</>
        }
      },
      Edit: ({ row, column, table }) => {
        const { setEditingRow, getState, setCreatingRow } = table
        const { editingRow, creatingRow } = getState()

        const defaultValue = moment(row.original[key], DATE_FORMAT, true) || null

        const isCreating = creatingRow?.id === row.id
        const isEditing = editingRow?.id === row.id

        const saveInputValueToRowCache = (newValue: Moment | null) => {
          const newDate = newValue?.format(DATE_FORMAT) ?? ''
          row._valuesCache = {
            ...row._valuesCache,
            [column.id]: newDate,
          }

          if (isCreating) {
            setCreatingRow(row)
          } else if (isEditing) {
            setEditingRow(row)
          }
        }

        return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label={name}
              name={key}
              format={DATE_FORMAT}
              defaultValue={defaultValue}
              onChange={(newValue: Moment | null) => saveInputValueToRowCache(newValue)}
              slotProps={{
                textField: {
                  variant: 'standard',
                  required: required,
                  error: !!validationErrors[key],
                  helperText: validationErrors[key] ?? note,
                  onFocus: () =>
                    setValidationErrors({
                      ...validationErrors,
                      [key]: undefined,
                    }),
                  sx: {
                    marginTop: '16px',
                    marginBottom: '16px',
                  },
                },
              }}
            />
          </LocalizationProvider>
        )
      },
    }
  }

  function createNumberColumn<T extends MRT_RowData>(header: ColumnHeader): MRT_ColumnDef<T> {
    const { name, key, note, required } = header
    return {
      accessorKey: key,
      header: name,
      muiEditTextFieldProps: {
        // There is a bug in the Material-UI TextField component when using type=number.
        // See below for more details:
        // https://mui.com/material-ui/react-text-field/#type-quot-number-quot
        // So for now, we are using type=text and validating the input value to be a number.
        type: 'text',
        required: required,
        error: !!validationErrors[key],
        helperText: validationErrors[key] ?? note,
        onFocus: () =>
          setValidationErrors({
            ...validationErrors,
            [key]: undefined,
          }),
        onChange: e => {
          const value = e.target.value
          if (!validateRequired(value)) {
            setValidationErrors({
              ...validationErrors,
              [key]: 'This field is required',
            })
          } else if (!validateNumber(value)) {
            setValidationErrors({
              ...validationErrors,
              [key]: 'This field must be a number',
            })
          } else {
            setValidationErrors({
              ...validationErrors,
              [key]: undefined,
            })
          }
        },
      },
      Header: ({ column }) => {
        return <TableHeader title={column.columnDef.header} note={note} required={required} />
      },
    }
  }

  function createKeyColumn<T extends MRT_RowData>(header: ColumnHeader): MRT_ColumnDef<T> {
    const { name, key, note } = header
    return {
      accessorKey: key,
      header: name,
      muiEditTextFieldProps: {
        type: 'text',
        required: true,
        disabled: true,
        error: !!validationErrors[key],
        helperText: validationErrors[key] ?? note,
      },
      Header: ({ column }) => {
        return <TableHeader title={column.columnDef.header} note={note} required={true} />
      },
      Edit: () => null, //don't render anything in the editing modal for this column
    }
  }

  function createColumns<T extends MRT_RowData>(sheet?: ConfigurationSheet<T>) {
    if (!sheet) return []

    const columns: MRT_ColumnDef<T>[] = sheet.data.headers.map((header: ColumnHeader) => {
      const { name, key, note, validationRule, unique } = header

      if (key === 'key') {
        return createKeyColumn(header)
      }

      if (!validationRule) {
        return createTextColumn(header)
      }

      switch (validationRule.ruleType) {
        case ValidationRuleType.ONE_OF_LIST:
          return createSelectColumn(header)

        case ValidationRuleType.CUSTOM_FORMULA: {
          switch (true) {
            // Handle isnumber(Cx) case (i.e. number column)
            case /^=ISNUMBER\([A-Z]\d+\)$/.test(validationRule.allowedValues[0]):
              return createNumberColumn(header)

            default:
              // eslint-disable-next-line no-console
              console.warn(
                `Unknown custom formula rule:
                ${validationRule.allowedValues[0]} for column ${name}. Will default to an optional text column.`
              )

              return createTextColumn({
                name,
                key,
                note,
                unique,
                required: false,
              })
          }
        }

        case ValidationRuleType.DATE_IS_VALID:
          return createDateColumn(header)

        default:
          // eslint-disable-next-line no-console
          console.warn(
            `The rule type ${validationRule.ruleType} for column ${name} is not supported. Will default to an optional text column.`
          )
          return createTextColumn({
            name,
            key,
            note,
            unique,
            required: false,
          })
      }
    })

    return columns
  }

  const refreshViaWarning = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    await fetchSheetData({ forceRefresh: false })
  }

  const handleCreatingRowSave: MRT_TableOptions<T>['onCreatingRowSave'] = async ({
    exitCreatingMode,
    values,
  }) => {
    const errors = hasErrors(values as T)
    if (errors) {
      return setValidationErrors(errors)
    }

    // clear previous validation errors
    setValidationErrors({})

    try {
      setIsCreating(true)

      await googleSheetsClient?.appendRow({
        slug,
        headers: sheet?.data.headers ?? [],
        values: values,
      })

      exitCreatingMode()

      //refresh the data after creating?
      await fetchSheetData({ forceRefresh: true })
    } catch (error: any) {
      setApiError(error.response.data.errors ?? 'An unknown error occurred')
    } finally {
      setIsCreating(false)
    }
  }

  const handleEditingRowSave: MRT_TableOptions<T>['onEditingRowSave'] = async ({
    exitEditingMode,
    row,
    values,
  }) => {
    if (!hasChanges(row, values as T)) {
      return exitEditingMode()
    }

    const errors = hasErrors(values as T)
    if (errors) {
      return setValidationErrors(errors)
    }

    // clear previous validation errors
    setValidationErrors({})

    try {
      setIsUpdating(true)

      await googleSheetsClient?.updateRow({
        slug,
        rowIndex: row.index + 1, // 1-based index
        headers: sheet?.data.headers ?? [],
        values: values,
        page: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      })

      exitEditingMode()

      //refresh the data after updating
      await fetchSheetData({ forceRefresh: true })
    } catch (error: any) {
      setApiError(error.response.data.errors ?? 'An unknown error occurred')
    } finally {
      setIsUpdating(false)
    }
  }

  const handleEditingRowCancel: MRT_TableOptions<T>['onEditingRowCancel'] = () => {
    setValidationErrors({})
  }

  const handleCreatingRowCancel: MRT_TableOptions<T>['onCreatingRowCancel'] = () => {
    setValidationErrors({})
  }

  const handlePaginationChange: MRT_TableOptions<T>['onPaginationChange'] = newPagination => {
    setPagination(newPagination)
  }

  async function handleDeleteRow(row: MRT_Row<T>) {
    try {
      setIsDeleting(true)

      await googleSheetsClient?.deleteRow({
        slug,
        rowIndex: row.index + 1, // 1-based index
        page: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      })

      setIsDeleting(false)
      setShowDeleteRowModal(undefined)

      //refresh the data after deleting
      await fetchSheetData({ forceRefresh: true })
    } catch (error: any) {
      setIsDeleting(false)
      setShowDeleteRowModal(undefined)

      setApiError(error.response.data.errors ?? 'An unknown error occurred')
    }
  }

  async function uploadCSVSheet(file: File) {
    setProcessingSheetUpload(true)
    const headers = sheet?.data.headers ?? []
    await googleSheetsClient?.uploadFile({
      slug,
      file,
      headers,
    })
  }

  async function clearSheetData() {
    await googleSheetsClient?.clearSheet({
      slug,
    })

    setshowClearDataModal(false)
    await fetchSheetData({ forceRefresh: false })
  }

  async function handleIntegrityCheck() {
    const headers = sheet?.data.headers ?? []

    if (headers.length === 0) {
      return setApiError('Sheet has no headers')
    }

    const newController = new AbortController()
    setController(newController)

    // TODO: Make this an env var or a tenant config option
    const pageSize = 50

    const totalBatches = Math.ceil(rowCount / pageSize)
    let page = 1
    let updatedRows = 0

    const signal = newController.signal

    try {
      setIntegrityCheckRowsUpdated(0)
      setIntegrityCheckProgress(0)

      while (page <= totalBatches) {
        if (signal.aborted) {
          throw new axios.Cancel('Operation canceled by the user.')
        }

        setIntegrityCheckProgress(page / totalBatches)

        const result = await googleSheetsClient?.checkIntegrity({
          slug,
          headers,
          page,
          pageSize,
          signal,
        })

        if (result) {
          updatedRows += result.updatedRows
        }

        page += 1

        // 1 second interval between requests to avoid rate limiting
        await new Promise(r => setTimeout(r, 1000))
      }

      setShowIntegrityCheckModal(false)
      setIntegrityCheckProgress(0)

      setIntegrityCheckRowsUpdated(updatedRows)
      setShowIntegrityCheckResultsModal(true)
    } catch (error: any) {
      setShowIntegrityCheckModal(false)
      setIntegrityCheckProgress(0)
      setIntegrityCheckRowsUpdated(0)
      setShowIntegrityCheckResultsModal(false)

      if (axios.isCancel(error)) {
        setShowIntegrityCheckCancelModal(true)
      } else {
        setApiError(error.response.data.errors ?? 'An unknown error occurred')
      }
    }
  }

  function handleCloseIntegrityCheckModal() {
    if (controller) {
      controller.abort()
      setController(null)
    }

    setShowIntegrityCheckModal(false)
  }

  function handleCloseCSVSheetModal() {
    setShowImportCSVModal(false)
  }

  async function handleCloseIntegrityCheckResultsModal() {
    setShowIntegrityCheckResultsModal(false)

    //refresh the data after running the integrity check
    await fetchSheetData({ forceRefresh: true })
  }

  async function handleCloseIntegrityCheckCancelModal() {
    setShowIntegrityCheckCancelModal(false)

    //refresh the data after running the integrity check
    await fetchSheetData({ forceRefresh: true })
  }

  function openDeleteConfirmModal(row: MRT_Row<T>) {
    setShowDeleteRowModal(row)
  }

  function hasMissingRequiredFields(requiredFields: string[], values: T) {
    return requiredFields.some(key => {
      if (key === 'key') return false // Ignore the 'key' column
      return values[key] === undefined || values[key] === null || values[key] === ''
    })
  }

  function hasChanges(row: MRT_Row<T>, values: T) {
    return Object.keys(values).some(header => {
      return row.original[header] !== values[header]
    })
  }

  function hasErrors(values: T) {
    // look for input validation errors (e.g. invalid date, numbers, etc.)
    if (Object.values(validationErrors).some(error => error)) {
      return validationErrors
    }

    // look for missing required fields
    const requiredFields =
      sheet?.data.headers.filter(header => header.required)?.map(header => header.key) ?? []

    if (hasMissingRequiredFields(requiredFields, values as T)) {
      return requiredFields.reduce((acc, key) => {
        if (!values[key]) {
          acc[key] = 'This field is required'
        }
        return acc
      }, {})
    }
  }

  function handleActivationRequest() {
    if (currentTenant) {
      createModelAmplificationRequest({
        tenantId: currentTenant.id,
        slug: slug,
      })
    } else {
      throw new Error('Current Tenant not found!')
    }
  }

  const columns = useMemo<MRT_ColumnDef<T>[]>(() => createColumns(sheet), [sheet, validationErrors])

  const fetchSheetData = async ({ forceRefresh }: { forceRefresh: boolean }) => {
    setIsLoading(true)
    const response = await googleSheetsClient?.getSheet(
      slug,
      pagination.pageIndex + 1,
      pagination.pageSize,
      forceRefresh
    )

    setSheet(response as ConfigurationSheet<T>)
    setRowCount(response?.pagination.totalRecords ?? 0)
    setIsLoading(false)
    setProcessingSheetUpload(!!response?.data?.sheetUpload?.processing)
  }

  const clearCacheAndRefreshData = async () => {
    await fetchSheetData({ forceRefresh: true })
  }

  useEffect(() => {
    fetchSheetData({ forceRefresh: false })
  }, [googleSheetsClient, slug, pagination])

  return (
    <>
      <Header>
        <BackButton onClick={() => history.goBack()} icon={ArrowLeft} variant="ghost">
          Back
        </BackButton>
        {modelAmplificationsGA?.enabled && (
          <Chip
            text={configSheetStateDetails['text']}
            color={configSheetStateDetails['color']}
            variant={configSheetStateDetails['variant']}
          />
        )}
      </Header>
      <SubHeader hideBorder={false}>
        <TitleWrapper>
          <H3>{sheet?.data?.title}</H3>
          <Subtitle>{sheet?.data?.description}</Subtitle>
        </TitleWrapper>
      </SubHeader>
      {modelAmplificationsGA?.enabled && sheet?.data?.provisionState === 'inactive' && (
        <ModelAmplificationsActivationNotice
          onActivationRequested={handleActivationRequest}
          activationButtonDisabled={rowCount === 0}
        />
      )}
      {modelAmplificationsGA?.enabled && sheet?.data?.provisionState === 'pending' && (
        <ModelAmplificationsActivationConfirmation />
      )}

      <TableWrapper>
        <SheetUploadFeedback
          processingUpload={processingSheetUpload}
          sheetUpload={sheet?.data.sheetUpload || {}}
          refreshViaWarning={refreshViaWarning}
        />
        {!sheet?.data && isLoading && <Loading />}
        {sheet?.data && columns && (
          <>
            <Table
              data={sheet.data.values}
              columns={columns}
              onCreatingRowSave={handleCreatingRowSave}
              onEditingRowSave={handleEditingRowSave}
              onCreatingRowCancel={handleCreatingRowCancel}
              onEditingRowCancel={handleEditingRowCancel}
              onPaginationChange={handlePaginationChange}
              openDeleteConfirmModal={openDeleteConfirmModal}
              reloadTableData={clearCacheAndRefreshData}
              isLoading={isLoading}
              isCreating={isCreating}
              isUpdating={isUpdating}
              isDeleting={isDeleting}
              enableEditing={enableEditing}
              rowCount={rowCount}
              pagination={pagination}
              processingSheetUpload={processingSheetUpload}
              runIntegrityCheck={() => setShowIntegrityCheckModal(true)}
              importCSVSheet={() => setShowImportCSVModal(true)}
              clearSheetData={() => setshowClearDataModal(true)}
              downloadSheet={() => setShowDownloadSheetModal(true)}
              slug={slug}
            />

            {apiError && <ApiErrorDialog error={apiError} onClose={() => setApiError(undefined)} />}

            {showDeleteRowModal && (
              <TableDeleteRowDialog
                open={showDeleteRowModal !== undefined}
                row={showDeleteRowModal}
                onDelete={handleDeleteRow}
                isDeleting={isDeleting}
                onClose={() => setShowDeleteRowModal(undefined)}
              />
            )}

            {showIntegrityCheckModal && (
              <IntegrityCheckModal
                progress={integrityCheckProgress}
                onRunIntegrityCheck={handleIntegrityCheck}
                onClose={handleCloseIntegrityCheckModal}
              />
            )}

            {showImportCSVModal && (
              <ImportCSVSheetModal
                processingSheetUpload={processingSheetUpload}
                uploadCSVSheet={uploadCSVSheet}
                onClose={handleCloseCSVSheetModal}
              />
            )}

            {showClearDataModal && (
              <ClearSheetDataModal
                clearSheet={clearSheetData}
                onClose={() => setshowClearDataModal(false)}
              />
            )}

            {showDownloadSheetModal && (
              <DownloadSheetModal slug={slug} onClose={() => setShowDownloadSheetModal(false)} />
            )}

            {showIntegrityCheckResultsModal && (
              <IntegrityCheckResultsModal
                updatedRows={integrityCheckRowsUpdated}
                onClose={handleCloseIntegrityCheckResultsModal}
              />
            )}

            {showIntegrityCheckCancelModal && (
              <IntegrityCheckCancelModal onClose={handleCloseIntegrityCheckCancelModal} />
            )}
          </>
        )}
      </TableWrapper>
    </>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SubHeader = styled.div<{ hideBorder: boolean }>`
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${p => (p.hideBorder ? 'none' : `solid 1px ${p.theme.BorderHairline}`)};
`

const BackButton = styled(Button)`
  padding-left: 0;
`

const TitleWrapper = styled.div`
  flex: 1;
  flex-direction: column;
`

const Subtitle = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${p => p.theme.ContentSecondary};
`

const TableWrapper = styled.div`
  padding: 16px 0;
`

export default ConfigSheet
