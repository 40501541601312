import React, { useState } from 'react'
import { Menu, MenuItem } from '@mui/material'
import { Help } from '@chordco/component-library/components/icons/standardIcons/Help'
import { ChevronDown } from '@chordco/component-library/components/icons/standardIcons/ChevronDown'
import { Button } from '@chordco/component-library'
import { ChatBubble } from '@chordco/component-library/components/icons/standardIcons/ChatBubble'
import useHelpScout from 'hooks/useHelpScout'
import HelpModal from 'components/help/HelpModal'
import useCurrentUser from 'redux/hooks/useCurrentUser'

type HelpButtonProps = {
  helpFile?: string
  helpModalOpened: boolean
}

const HelpButton: React.FC<HelpButtonProps> = ({ helpFile, helpModalOpened }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [showHelpModal, setShowHelpModal] = useState(helpModalOpened)
  const [showHelpScout, setShowHelpScout] = useState(false)

  const user = useCurrentUser()

  const [startChat, stopChat, suggestLinks] = useHelpScout({
    user,
    onClose: () => setShowHelpScout(false),
  })

  const toggleHelpScout = () => {
    if (showHelpScout) {
      stopChat()
    } else {
      suggestLinks([
        { label: 'Documentation', url: 'https://docs.chord.co/chord-commerce-data-platform' },
        { label: 'Product Release Notes', url: 'https://chord.launchnotes.io' },
      ])
      startChat()
    }

    setShowHelpScout(!showHelpScout)
  }

  const toggleHelpModal = () => setShowHelpModal(!showHelpModal)

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        purpose="tertiary"
        icon={ChevronDown}
        onClick={handleMenuOpen}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        Help
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          marginTop: 1,
        }}
      >
        {helpFile && (
          <MenuItem
            onClick={() => {
              handleMenuClose()
              toggleHelpModal()
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Help />
            Help & Getting Started
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            handleMenuClose()
            toggleHelpScout()
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <ChatBubble />
          Contact Support
        </MenuItem>
      </Menu>

      {showHelpModal && helpFile && <HelpModal helpFile={helpFile} onClose={toggleHelpModal} />}
    </>
  )
}

export default HelpButton
