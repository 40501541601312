import { useEffect, useMemo, useState } from 'react'
import { useLookerData } from 'redux/state/looker'
import useEmbedUrl from 'components/looker/useEmbedUrl'
import { useIsLoading } from 'redux/utils'
import { AvailableUserReport } from 'types'
import usePinnedReport from 'redux/hooks/usePinnedReport'

export interface UseReportDataResult {
  report: AvailableUserReport | undefined
  isPinned: boolean
  embedReportUrl: string | null
  isLoading: boolean
  isInitialized: boolean
}

/**
 * Fetches data and embed URL for a given report slug
 */
export const useReportData = (slug: string): UseReportDataResult => {
  const {
    state: { availableReports },
    getAvailableReports,
  } = useLookerData()

  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    if (getAvailableReports) {
      getAvailableReports().finally(() => setIsInitialized(true))
    }
  }, [])

  const report = useMemo(() => {
    const allReports = [
      ...availableReports.dashboards,
      ...availableReports.looks,
      ...availableReports.explores,
    ]
    return allReports.find(report => report.slug === slug)
  }, [availableReports, slug])

  const { embedUrls, isLoading: isLoadingURL } = useEmbedUrl(
    slug,
    report?.metricType ?? 'dashboard'
  )

  const { isPinned } = usePinnedReport(slug)

  const embedReportUrl = embedUrls?.[slug] ?? null

  const isLoadingReports = useIsLoading('getAvailableReports')
  const isLoading = isLoadingReports || isLoadingURL

  return {
    report,
    isPinned,
    embedReportUrl,
    isLoading,
    isInitialized,
  }
}
