import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { standardIcons } from '../icons'
import { Body, H3 } from '../type'
import { Button, Icon } from '../controls'
import { Overlay } from './Overlay'
import useMixpanel from 'hooks/useMixpanel'
import { MixpanelEventType } from 'utils/mixpanel/types'

interface Props {
  children: React.ReactNode
  title: string
  left?: any
  rightButtons?: {
    id: number
    icon: any
    onClick: Function
  }[]
  onClose: any
  width?: string
  scrollable?: boolean
  padding?: string
  hideHeaderBorder?: boolean
  hideFooter?: boolean
  name?: string
  location?: string
  type?: string
}

export const Modal: React.FC<Props> = ({
  title,
  left: Left,
  children,
  rightButtons = [],
  onClose,
  width,
  scrollable = true,
  padding = '24px',
  hideHeaderBorder = true,
  hideFooter = true,
  name,
  location,
  type,
}) => {
  const [top, setTop] = useState(true)
  const ref = useRef() as any
  const [show, setShow]: [boolean, any] = useState(false)
  const { trackEvent } = useMixpanel()

  const trackModalOpened = () => {
    if (name && location && type) {
      trackEvent(MixpanelEventType.ModalOpened, {
        'Modal Name': name,
        'Modal Type': type,
        'Page Name': location,
      })
    }
  }

  const trackModalClosed = () => {
    if (name && location && type) {
      trackEvent(MixpanelEventType.ModalClosed, {
        'Modal Name': name,
        'Modal Type': type,
        'Page Name': location,
      })
    }
  }

  useEffect(() => {
    setTimeout(() => {
      trackModalOpened()
      setShow(true)
    })
  }, [])

  const onExit = () => {
    setShow(false)
    setTimeout(() => {
      trackModalClosed()
      onClose()
    }, 100)
  }

  return (
    <Overlay show={show} onExit={onExit}>
      <Wrapper width={width} show={show}>
        <Header top={top} hideBorder={!!hideHeaderBorder}>
          {Left && (
            <LeftContainer>
              <Left />
            </LeftContainer>
          )}
          <StyledH3>{title}</StyledH3>
          <RightButtons>
            {rightButtons &&
              rightButtons.map(({ id, icon, onClick }: any) => (
                <Icon key={id} icon={icon} onClick={onClick} purpose="ghost" size="small" />
              ))}
            <Icon
              icon={standardIcons.Exit}
              purpose="ghost-secondary"
              onClick={onExit}
              size="small"
            />
          </RightButtons>
        </Header>
        <Content
          ref={ref}
          onScroll={() => setTop(ref?.current?.scrollTop === 0)}
          scrollable={scrollable}
          padding={padding}
          hideFooter={hideFooter}
        >
          {children}
        </Content>
        {!hideFooter && (
          <Footer>
            <Button purpose="primary" onClick={onExit}>
              Close
            </Button>
          </Footer>
        )}
      </Wrapper>
    </Overlay>
  )
}

const Wrapper = styled.div<{
  width?: string
  show: boolean
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 300px;
  width: ${p => p.width || 'auto'};
  background-color: ${p => p.theme.ComponentWorkspaceBgPage};
  color: ${p => p.theme.ContentPrimary};
  border-radius: ${p => p.theme.CardLargeRadius};
  transition: 100ms;
  transform: translate(-50%, -50%) scale(${p => (p.show ? 1 : 0.7)});
`

const Header = styled.div<{ top: boolean; hideBorder: boolean }>`
  box-sizing: border-box;
  height: 72px;
  padding: 16px 16px 16px 24px;
  border-radius: 8px 8px 0 0;
  transition: box-shadow 200ms;
  border-bottom: ${p => (p.hideBorder ? 'none' : `solid 1px ${p.theme.BorderHairline}`)};
`

const StyledH3 = styled(H3)`
  margin-top: 5px;
`

const LeftContainer = styled.div`
  float: left;
  margin: 10px 16px 0 0;
`

const Content = styled(Body)<{ scrollable: boolean; padding: string; hideFooter: boolean }>`
  max-height: calc(100vh - 200px);
  padding-top: 0;
  padding-bottom: 0;
  padding-right: ${p => (p.padding ? p.padding : '24px')};
  padding-left: ${p => (p.padding ? p.padding : '24px')};
  white-space: pre-wrap;
  overflow-y: ${p => (p.scrollable ? 'auto' : 'visible')};
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  margin-bottom: ${p => (p.hideFooter ? '0' : '70px')};
`

const RightButtons = styled.div`
  display: flex;
  float: right;
  margin: -32px -4px 0 0;
`

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${p => p.theme.ComponentWorkspaceBgPage};
  border-top: 1px solid ${p => p.theme.BorderHairline};
  padding: 16px;
  z-index: 1;
  height: 70px;
  border-radius: 0 0 ${p => p.theme.CardLargeRadius} ${p => p.theme.CardLargeRadius};
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`
