import React, { useState } from 'react'
import { useIsMobile } from 'hooks'
import { TimezonePanel } from 'components/data_settings/timezonePanel'
import { WeekDefinitionPanel } from './weekDefinitionPanel'
import { FiscalYearAndPeriodPanel } from './fiscalYearAndPeriodPanel'
import ChangeLogPanel from './changeLogPanel'
import { Button, H1, H2 } from '@chordco/component-library'
import styled from 'styled-components'
import { SaveConfirmationModal } from './SaveConfirmationModal'
import { DataSettings, Timezones, DataSettingsPayload, DataSettingsChangelogEntries } from 'types'
import useUserRole from 'redux/hooks/useUserRole'

type Props = {
  timezones: Timezones
  dataSettings: DataSettings
  dataSettingsChangelogEntries: DataSettingsChangelogEntries
}

export const DataSettingsContainer: React.FC<Props> = ({
  timezones,
  dataSettings,
  dataSettingsChangelogEntries,
}) => {
  const isMobile = useIsMobile()
  const Heading = isMobile ? H2 : H1
  const role = useUserRole()

  const [showSaveAlertModal, setShowSaveAlertModal] = useState(false)

  const [dataPayload, setDataPayload] = useState<DataSettingsPayload>({
    timezone: dataSettings.timezone || 'America - New York',
    startOfWeek: dataSettings.startOfWeek.label || 'Sunday',
    startOfYear: dataSettings.startOfYear.label || 'January',
    fiscalPeriod: dataSettings.fiscalPeriod || '4-4-5',
  })

  const handleChange = (key: string, value: string) => {
    setDataPayload({ ...dataPayload, [key]: value })
  }

  return (
    <>
      <HeadingContainer>
        <Heading>Data Setup</Heading>
        <SubHeading>Changes will not take effect until next refresh</SubHeading>
        {(role === 'admin' || role === 'superuser' || role === 'data_admin') && (
          <ButtonContainer>
            <Button
              name="Save Changes"
              location="Data Setting Page"
              type="submit"
              purpose="primary"
              centreContent={true}
              onClick={() => setShowSaveAlertModal(true)}
            >
              Save Changes
            </Button>
          </ButtonContainer>
        )}
        {showSaveAlertModal && (
          <SaveConfirmationModal data={dataPayload} onClose={() => setShowSaveAlertModal(false)} />
        )}
      </HeadingContainer>
      <DataSettingContainer>
        <TimezonePanel
          timezone={dataPayload.timezone}
          lookerTimezones={timezones['looker_timezones']}
          setTimezone={timezone => handleChange('timezone', timezone)}
        />

        <WeekDefinitionPanel
          weekStart={dataPayload.startOfWeek}
          setWeekStart={weekStart => handleChange('startOfWeek', weekStart)}
        />

        <FiscalYearAndPeriodPanel
          yearStart={dataPayload.startOfYear}
          setYearStart={yearStart => handleChange('startOfYear', yearStart)}
          fiscalPeriod={dataPayload.fiscalPeriod}
          setFiscalPeriod={fiscalPeriod => handleChange('fiscalPeriod', fiscalPeriod)}
        />

        <ChangeLogPanel dataSettingsChangelogEntries={dataSettingsChangelogEntries} />
      </DataSettingContainer>
    </>
  )
}

const DataSettingContainer = styled.div`
  padding-top: 15px;
`

const HeadingContainer = styled.div`
  position: relative;
  padding-bottom: 30px;
`
const SubHeading = styled.div`
  font-size: 14px;
  color: ${p => p.theme.ContentTertiary};
  margin-top: 10px;
`
const ButtonContainer = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 10px;
  color: ${p => p.theme.ContentTertiary};
`
